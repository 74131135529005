import * as React from 'react';
import { Sidebar } from './controls/sidebar';
import { GlobalState, SearchData } from './globalState';
import { Header } from './controls/header';
import { NavigationManager } from './tools/navigationManager';
import { PageDispatcher } from './pages/pageDispatcher';
import { Footer } from './controls/footer';
import { Button } from './controls/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { PriceTools } from './tools/priceTools';
import { CardColor } from './entities/cardColor';
import { CardRarity } from './entities/cardRarity';
import { CardFormat } from './entities/cardFormat';
import { Collection } from './entities/collection';
import { CountDialog } from './controls/countDialog';
import { QueryDialog } from './controls/queryDialog';
import { ListDialog } from './controls/listDialog';
import { CardCondition } from './entities/cardCondition';
import { NewPlayerDialog } from './controls/newPlayerDialog';
import { DeckDialog } from './controls/deckDialog';
import { DeckCountDialog } from './controls/deckCountDialog';
import { ExpansionPicker } from './controls/expansionPicker';
import { ContextMenu } from './controls/contextMenu';
import { PatreonTools } from './tools/patreonTools';
import { CustomMenu } from './controls/customMenu';
import { ColorDialog } from './controls/colorDialog';
import { CardSuperType } from './entities/cardSuperType';
import { CardImageDialog } from './controls/cardImageDialog';
import { CustomSortDialog } from './controls/customSortDialog';
import { LinkTools } from './tools/linkTool';
import { MoveInsideDeckDialog } from './controls/moveInsideDeckDialog';
import { TagDialog } from './controls/tagDialog';
import { ListEditorDialog } from './controls/listEditorDialog';
import { CustomChecklistDialog } from './controls/customChecklistDialog';
import { CardGrading } from './entities/cardGrading';
import { CustomDatabase } from './controls/customDatabase';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { LabelledTexbox } from './controls/labelledTextbox';
import { ShoppingAssistant } from './controls/shoppingAssistant';
import { NewBlockDialog } from './controls/newBlockDialog';
import { CardLanguage } from './entities/cardLanguage';
import { UserDialog } from './controls/userDialog';
import { HiddenCardList } from './controls/hiddenCardList';
import { CardIconDialog } from './controls/cardIconDialog';
import { CustomIcon } from './entities/customIcon';
import { CardDataDialog } from './controls/cardDataDialog';

require('./scss/app.scss');
require('./scss/sidebar.scss');
require('./scss/cards/deckCard.scss');
require('./scss/cards/listCard.scss');
require('./scss/cards/blockCard.scss');
require('./scss/cards/setCard.scss');
require('./scss/cards/cardCard.scss');
require('./scss/controls/button.scss');
require('./scss/controls/slider.scss');
require('./scss/controls/dual-slider.scss');
require('./scss/controls/checkbox.scss');
require('./scss/controls/rangeSlider.scss');
require('./scss/controls/labelledListbox.scss');
require('./scss/controls/labelledTextbox.scss');
require('./scss/controls/labelledCheckbox.scss');
require('./scss/controls/labelledDatepicker.scss');
require('./scss/controls/labelledTagPicker.scss');
require('./scss/controls/multiSelect.scss');
require('./scss/controls/countDialog.scss');
require('./scss/controls/queryDialog.scss');
require('./scss/controls/deckDialog.scss');
require('./scss/controls/listEditorDialog.scss');
require('./scss/controls/moveInsideDeckDialog.scss');
require('./scss/controls/colorPicker.scss');
require('./scss/controls/deckCountDialog.scss');
require('./scss/controls/numberControl.scss');
require('./scss/controls/expansionPicker.scss');
require('./scss/controls/barChart.scss');
require('./scss/controls/pieChart.scss');
require('./scss/controls/contextMenu.scss');
require('./scss/controls/dataGrid.scss');
require('./scss/controls/tagDialog.scss');
require('./scss/controls/userDialog.scss');
require('./scss/controls/shoppingAssistant.scss');
require('./scss/controls/deckPicker.scss');
require('./scss/controls/groupedList.scss');
require('./scss/controls/cardDataDialog.scss');
require('./scss/controls/ads.scss');

declare const Android: any;

export class App extends React.Component<
    any,
    {
        isLoading: boolean;
        isSidebarOpened: boolean;
        errorMessage: string;
        progressMessage: string;
        infoMessage: string;
        infoLink: string;
        offlineMessage: string;
        confirmationDialogMessage: string;
        infoDialogMessage: string;
        reloadMessage: string
        darkMode: boolean;
        generalMessage: string;
        isBlurred: boolean;
        isPatreon: boolean;
        confirmationOk: string;
        confirmationCancel: string;
        query: string;
        progressValue: number;
    }
> {
    private globalState = new GlobalState();
    private confirmationCallback: (value: boolean) => void;
    private currentWidth: number;
    private deferredPrompt: any;
    private displayControlOverlay = false;

    public navigationManager: NavigationManager;

    public static readonly Latest = '13.1.0';

    public static NeedSidebarOverlay() {
        return window.innerWidth <= 768 || GlobalState.IsRunningOnFoldableDevice;
    }

    constructor(props: any) {
        super(props);

        CardFormat.GetUpdatedFormatsAsync(this.globalState);

        // Store deep link
        const params = (new URL(location.toString())).searchParams;
        if (params.has('card')) {
            this.globalState.storeSettings('DeepLink', params.get('card')!);
        }

        this.navigationManager = new NavigationManager(this.globalState);
        PriceTools.UpdateRate();
        CardColor.CreateList(this.globalState);
        CardRarity.CreateList(this.globalState);
        CardFormat.CreateList(this.globalState);
        CardCondition.CreateList(this.globalState);
        CardGrading.CreateList(this.globalState);
        CardSuperType.CreateList(this.globalState);
        CardLanguage.CreateList(this.globalState);
        CustomIcon.CreateList(this.globalState);

        PatreonTools.GetPledgeLevelAsync().then((value) => {
            this.setState({ isPatreon: value });
        });

        this.globalState.onPatreonDisconnected.add(() => {
            this.setState({ isPatreon: false });
        });

        this.globalState.onPatreonConnected.add(() => {
            this.setState({ isPatreon: true });
        });

        const controlOverlay = ((window.navigator as any).windowControlsOverlay);

        this.displayControlOverlay = controlOverlay && controlOverlay.visible;

        if (!this.displayControlOverlay) {
            document.body.style.setProperty('--fallback-title-bar-height', '0px');
        }

        this.updateAccentColor(GlobalState.LoadSettings('AccentColor', '#CC7529'));

        let darkModeEnabled = GlobalState.LoadBoolSettings('DarkMode', false);
        if (GlobalState.LoadBoolSettings('AutoDarkMode', false)) {
            const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            const lightModeMediaQuery = window.matchMedia('(prefers-color-scheme: light)');
            if (darkModeMediaQuery.matches) {
                darkModeEnabled = true;
            } else if (lightModeMediaQuery.matches) {
                darkModeEnabled = false;
            }

            darkModeMediaQuery.addListener((e) => {
                const finalState = e.matches;
                this.globalState.refreshTheme(finalState);
            });
        }

        this.state = {
            isLoading: true,
            isBlurred: false,
            isSidebarOpened: App.NeedSidebarOverlay() && GlobalState.LoadBoolSettings('SidebarOpened', false),
            errorMessage: '',
            progressMessage: '',
            infoMessage: '',
            reloadMessage: '',
            infoLink: '',
            confirmationDialogMessage: '',
            infoDialogMessage: '',
            offlineMessage: '',
            generalMessage: '',
            darkMode: darkModeEnabled,
            isPatreon: false,
            confirmationOk: 'Ok',
            confirmationCancel: 'Cancel',
            query: '',
            progressValue: 0
        };

        if (this.state.darkMode) {
            const windowAsAny = window as any;
            if (windowAsAny.webkit && windowAsAny.webkit.messageHandlers) {
                windowAsAny.webkit.messageHandlers.myMsgHandler.postMessage('dark');
            }
        }

        this.currentWidth = window.innerWidth;

        window.addEventListener('resize', () => {
            if (this.currentWidth !== window.innerWidth && this.state.isSidebarOpened && App.NeedSidebarOverlay()) {
                this.setState({ isSidebarOpened: false });
            }
            this.currentWidth = window.innerWidth;
            this.globalState.onWindowSizeChanged.notifyObservers(window.innerWidth);
        });

        this.globalState.onError.add((error) => {
            if (error && this.state.errorMessage) {
                return;
            }

            this.setState({ errorMessage: error });
            setTimeout(() => {
                this.setState({ errorMessage: '' });
            }, 5000);
        });

        this.globalState.onReloadRequiredError.add((error) => {
            if (error && this.state.reloadMessage) {
                return;
            }

            this.setState({ reloadMessage: error });
        });

        this.globalState.onMessage.add((message) => {
            let infoLink = '';

            if (message.indexOf('Patreon.com') !== -1) {
                infoLink = 'https://www.patreon.com/urzagatherer';
            }

            this.setState({ infoMessage: message, infoLink });
            setTimeout(() => {
                this.setState({ infoMessage: '', infoLink: '' });
            }, 5000);
        });

        this.globalState.onConfirmDialog = (message, ok, cancel, done) => {
            this.setState({
                confirmationDialogMessage: message,
                confirmationOk: ok,
                confirmationCancel: cancel,
                isBlurred: true
            });

            this.confirmationCallback = done;
        };

        this.globalState.onWaitRingRequired.add((request) => {
            this.setState({ isLoading: request });
        });

        this.globalState.onProgressMessageUpdate.add((message) => {
            if (message === this.state.progressMessage) {
                return;
            }
            this.setState({ progressMessage: message });
        });

        this.globalState.onProgressValueUpdate.add((value) => {
            if (value === this.state.progressValue) {
                return;
            }

            this.setState({ progressValue: value });
        });

        this.globalState.onDarkModeChanged.add((value) => {
            this.setState({ darkMode: value });
        });

        this.globalState.onAccentColorChanged.add((value) => {
            this.updateAccentColor(value);
        });

        this.globalState.onBlurRequired.add((value) => {
            this.setState({ isBlurred: value });
        });

        this.globalState.onBackgroundImageChanged.add(() => {
            this.forceUpdate();
        });

        // PWA install prompt
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();

            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;

            this.showInstallationPrompt();
        });
    }

    updateAccentColor(color: string) {
        document.body.style.setProperty('--accent-color', color);

        // Android
        if (typeof Android !== 'undefined' && Android.setColor) {
            Android.setColor(color);
        }

        const metaThemeColor = document.querySelector('meta[name=theme-color]');
        if (metaThemeColor) {
            metaThemeColor.setAttribute('content', color);
        }
    }

    updateOnlineStatus() {
        this.setState({ offlineMessage: navigator.onLine ? '' : this.globalState.translate('OfflineWarning') });
    }

    getAuthCookiePresence(): boolean {
        return GlobalState.LoadSettings('loggedOnce', '') === 'true';
    }

    showInstallationPrompt() {
        if (!this.deferredPrompt) {
            return;
        }
        if (!GlobalState.LoadBoolSettings('InstallPrompt', false)) {
            this.globalState.storeBoolSettings('InstallPrompt', true);

            this.globalState
                .showConfirmDialog(this.globalState.translate('InstallationPrompt'), 'Yes', 'No')
                .then((response) => {
                    if (response) {
                        this.deferredPrompt.prompt();
                        // Wait for the user to respond to the prompt
                        this.deferredPrompt.userChoice.then((choiceResult: any) => {
                            if (choiceResult.outcome === 'accepted') {
                                console.log('User accepted the A2HS prompt');
                            } else {
                                console.log('User dismissed the A2HS prompt');
                            }
                            this.deferredPrompt = null;
                        });
                    }
                });
        }
    }

    launch() {
        this.globalState.firstConnection = false;

        Collection.LoginAsync()
            .then(() => {
                this.setState({ isLoading: false });
                this.navigationManager.restore();

                // Install prompt
                this.showInstallationPrompt();

                // Deep linking
                const deepLink = GlobalState.LoadSettings('DeepLink');
                if (deepLink) {
                    this.globalState.storeSettings('DeepLink', '');
                    const cardId = parseInt(deepLink || '0') || 0;
                    this.globalState.onSelectedMenuIndexChanged.notifyObservers({
                        index: -4,
                        uniqueID: 0,
                        cardIds: [cardId]
                    });
                }
            })
            .catch((error: string) => {
                setTimeout(() => {
                    this.setState({ errorMessage: error });
                }, 16);
            });

        this.globalState.onImageLoadingCountChanged.add((count) => {
            const target = document.querySelector('.imageLoadingCount');

            if (!target) {
                return;
            }
            if (count === 0) {
                if (!target.classList.contains('collapsed')) {
                    target.classList.add('collapsed');
                }
                return;
            }

            target.classList.remove('collapsed');

            target.innerHTML = count + this.globalState.translateWithPlural('Downloads in progress', count);
        });

        window.addEventListener('online', () => this.updateOnlineStatus());
        window.addEventListener('offline', () => this.updateOnlineStatus());

        window.addEventListener('keydown', evt => {

            if (evt.key === 'F3') {
                evt.preventDefault();
                this.globalState.onSearchShortcut.notifyObservers();
            }
        });

        window.addEventListener('keyup', evt => {
            this.globalState.onKeyUp.notifyObservers(evt);
        });

        this.updateOnlineStatus();

        if (GlobalState.LoadSettings('latest') !== App.Latest) {
            this.setState({
                infoDialogMessage:
                    '<b>' +
                    this.globalState.translate('WhatsnewIn') +
                    '<div>' +
                    this.globalState.whatsNew[0].replace(':', '').replace('<i>', '').replace('</i>', '</b>') +
                    '</div>'
            });
            this.globalState.storeSettings('latest', App.Latest, true);
        }

        setTimeout(() => {
            // Patreon ads
            if (!PatreonTools.IsPatreon) {
                let count = GlobalState.LoadIntSettings('PatreonCount', 0);

                if (count === 10) {
                    count = 0;
                    this.globalState.onMessage.notifyObservers(this.globalState.translate('PatreonAds'));
                } else {
                    count += 1;
                }

                this.globalState.storeNumberSettings('PatreonCount', count, true);
            }
        }, 10000);

        try {
            this.getSystemWideMessageAsync();
        } catch {
            // Ignore error
        }
    }

    async getSystemWideMessageAsync() {
        const language = navigator.language.indexOf('fr-') >= 0 || GlobalState.LoadSettings('Language') === 'FR' ? 'FR' : 'US';
        const response = await fetch('https://urzabackend.azurewebsites.net/errorMessage' + language);
        const message = await response.text();

        if (!message) {
            return;
        }

        this.globalState.onMessage.notifyObservers(message);
    }

    componentDidMount() {
        if ((location.search === '') && !this.getAuthCookiePresence() && this.globalState.firstConnection) {
            this.setState({ generalMessage: this.globalState.translate('SyncNotAllowed') });
            return;
        }

        this.launch();
    }

    onClientClick() {
        if (this.state.isSidebarOpened && App.NeedSidebarOverlay()) {
            this.setState({ isSidebarOpened: false });
        }
    }

    onConfirmation(value: boolean) {
        this.setState({ confirmationDialogMessage: '', isBlurred: false });

        this.confirmationCallback(value);
    }

    doSearch() {
        const searchBox = document.querySelector('.search-box-text')! as HTMLInputElement;

        if (searchBox && searchBox.value) {
            const searchData = new SearchData();

            searchData.name = searchBox.value;

            this.globalState.onSelectedMenuIndexChanged.notifyObservers({ index: -3, searchData: searchData });
        }
    }

    blurTextField(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (evt.which === 13) {
            this.doSearch();
        }
    }

    public render() {
        const translate = this.globalState.translate.bind(this.globalState);

        let appClassName = this.state.isLoading || this.state.isBlurred ? 'app blurred' : 'app';

        if (this.state.darkMode) {
            appClassName += ' dark';
        }

        const suggestionForNames = Collection.Cards.map((c) => c.nameEn);

        if (Collection.UseFrenchForTexts) {
            for (const cardName of Collection.Cards.map((c) => c.nameFr)) {
                if (!cardName) {
                    continue;
                }
                suggestionForNames.push(cardName);
            }
        }

        const backgroundImage = GlobalState.LoadSettings('BackgroundImage');

        return (
            <>
                {
                    this.displayControlOverlay &&
                    <div id="titleBarContainer">
                        <div id="titleBar">
                            <Button
                                globalState={this.globalState}
                                onClicked={() => history.back()}
                                className="back-button"
                            >
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </Button>
                            <div className="draggable title-text">UrzaGatherer</div>
                            <div className="draggable search-title-back-left"></div>
                            <div className="search-title">
                                <LabelledTexbox
                                    label=""
                                    globalState={this.globalState}
                                    onlyInput={true}
                                    className="search-box-text"
                                    onKeyPress={(evt) => this.blurTextField(evt)}
                                    onChange={(value) => {
                                        this.setState({ query: value });
                                    }}
                                    onSuggestionPicked={() => {
                                        setTimeout(() => {
                                            this.doSearch();
                                        });
                                    }}
                                    suggestions={suggestionForNames}
                                    value={this.state.query}
                                    placeholder={translate('search...')}
                                />
                            </div>
                            <div className="draggable search-title-back-right"></div>
                        </div>
                    </div>
                }
                <div className="topRoot">
                    <div className={appClassName} id="app">
                        <div
                            className="client"
                            id="client"
                            onClick={() => this.onClientClick()}>
                            {
                                backgroundImage &&
                                <div
                                    className='backgroundImage'
                                    style={
                                        {
                                            backgroundImage: `url("${backgroundImage}")`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            filter: 'blur(4px) opacity(0.3)'
                                        }
                                    }>
                                </div>
                            }
                            <Header globalState={this.globalState} />
                            <div className="content">
                                <PageDispatcher globalState={this.globalState} darkMode={this.state.darkMode} />
                            </div>
                            <div className="imageLoadingCount collapsed" />
                            <Footer globalState={this.globalState} />
                            <div
                                className={
                                    this.state.isSidebarOpened && App.NeedSidebarOverlay()
                                        ? 'clickInterceptor'
                                        : 'clickInterceptor invisible'
                                }
                            >
                                &nbsp;
                            </div>
                        </div>
                        <Sidebar
                            globalState={this.globalState}
                            isPatreon={this.state.isPatreon}
                            isOpen={this.state.isSidebarOpened}
                            onHamburgerClicked={() => {
                                const newState = !this.state.isSidebarOpened;
                                this.setState({ isSidebarOpened: newState });

                                this.globalState.storeBoolSettings('SidebarOpened', newState, true);
                            }}
                        />
                    </div>
                    <div className={this.state.isLoading ? 'wait-ring' : 'wait-ring wait-ring-disabled'}>
                        <img id="wait-ring" className="wait-ring-logo" src="images/logo.png" />
                    </div>
                    {/* Progress message */}
                    <div className={'progress ' + (this.state.progressMessage ? 'enabled' : 'disabled')}>
                        <div className="background" style={{
                            width: `${this.state.progressValue * 100}%`
                        }} />
                        <div className="message">{this.state.progressMessage}</div>
                        <div className="close" onClick={() => this.globalState.onCancelImageDownloadRequired.notifyObservers()}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                    </div>
                    {/* Error message */}
                    <div className={'notification error ' + (this.state.errorMessage ? 'enabled' : 'disabled')}>
                        <div className="message">{this.state.errorMessage}</div>
                        <div className="close" onClick={() => this.setState({ errorMessage: '' })}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                    </div>
                    {/* Info message */}
                    <div className={'notification info ' + (this.state.infoMessage ? 'enabled' : 'disabled')}>
                        <div
                            className={'message' + (this.state.infoLink ? ' pointer' : '')}
                            onClick={() => {
                                if (this.state.infoLink) {
                                    LinkTools.OpenLink(this.state.infoLink);
                                }
                            }}
                        >
                            {this.state.infoMessage}
                        </div>
                        <div className="close" onClick={() => this.setState({ infoMessage: '' })}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                    </div>
                    {/* Offline message */}
                    <div className={'notification offline ' + (this.state.offlineMessage ? 'enabled' : 'disabled')}>
                        <div className="message">{this.state.offlineMessage}</div>
                        <div className="close" onClick={() => this.setState({ offlineMessage: '' })}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                    </div>
                    {this.state.infoDialogMessage && (
                        <div className="info-dialog-container">
                            <div className="info-dialog-background"></div>
                            <div className={'info-dialog' + (this.state.darkMode ? ' dark' : '')}>
                                <div
                                    className="info-dialog-title"
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.infoDialogMessage
                                    }}
                                ></div>
                                <div className="info-dialog-accept">
                                    <Button
                                        tabIndex={1}
                                        className="info-dialog-button"
                                        globalState={this.globalState}
                                        onClicked={() => this.setState({ infoDialogMessage: '' })}
                                    >
                                        {translate('OK')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.confirmationDialogMessage && (
                        <div className="dialog-container">
                            <div className="dialog-background"></div>
                            <div className={'dialog' + (this.state.darkMode ? ' dark' : '')}>
                                <div className="dialog-title">{this.state.confirmationDialogMessage}</div>
                                <div className="dialog-accept">
                                    <Button
                                        tabIndex={1}
                                        className="dialog-button"
                                        globalState={this.globalState}
                                        onClicked={() => this.onConfirmation(true)}
                                    >
                                        {translate(this.state.confirmationOk)}
                                    </Button>
                                </div>
                                <div className="dialog-cancel">
                                    <Button
                                        tabIndex={2}
                                        className="dialog-button"
                                        globalState={this.globalState}
                                        onClicked={() => this.onConfirmation(false)}
                                    >
                                        {translate(this.state.confirmationCancel)}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {this.state.generalMessage && (
                        <div className="general-dialog-container">
                            <div className="general-dialog-background"></div>
                            <div className={'general-dialog' + (this.state.darkMode ? ' dark' : '')}>
                                <div className="general-dialog-title">UrzaGatherer</div>
                                <div
                                    className="general-dialog-message"
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.generalMessage
                                    }}
                                ></div>
                                <div className="general-dialog-onedrive">
                                    <Button
                                        tabIndex={1}
                                        className="general-dialog-button"
                                        globalState={this.globalState}
                                        onClicked={() => {
                                            this.globalState.storeSettings('provider', 'OneDrive');
                                            location.href = 'index.html?log-onedrive';
                                        }}
                                    >
                                        {'OneDrive'}
                                    </Button>
                                </div>
                                <div className="general-dialog-dropbox">
                                    <Button
                                        tabIndex={1}
                                        className="general-dialog-button"
                                        globalState={this.globalState}
                                        onClicked={() => {
                                            this.globalState.storeSettings('provider', 'Dropbox');
                                            location.href = 'index.html?log-dropbox';
                                        }}
                                    >
                                        {'Dropbox'}
                                    </Button>
                                </div>
                                <div className="general-dialog-cancel">
                                    <Button
                                        tabIndex={1}
                                        className="general-dialog-button"
                                        globalState={this.globalState}
                                        onClicked={() => {
                                            this.globalState.workLocally = true;
                                            this.setState({ generalMessage: '' });
                                            this.launch();
                                        }}
                                    >
                                        {translate('WorkOffline')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Reload message */}
                    <div className={'notification error ' + (this.state.reloadMessage ? 'enabled' : 'disabled')}>
                        <div
                            className={'message' + (this.state.reloadMessage ? ' pointer' : '')}
                            onClick={() => {
                                location.reload();
                            }}
                        >
                            {this.state.reloadMessage}
                        </div>
                        <div className="close" onClick={() => this.setState({ reloadMessage: '' })}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </div>
                    </div>
                    <div className="measure-test" id="Test" />
                    <CustomMenu globalState={this.globalState} darkMode={this.state.darkMode} />
                    <ContextMenu globalState={this.globalState} darkMode={this.state.darkMode} />
                    <ShoppingAssistant globalState={this.globalState} darkMode={this.state.darkMode} />
                    <ColorDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <CountDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <DeckCountDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <ListEditorDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <TagDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <UserDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <QueryDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <ListDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <NewPlayerDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <NewBlockDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <MoveInsideDeckDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <DeckDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <ExpansionPicker globalState={this.globalState} darkMode={this.state.darkMode} />
                    <CardImageDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <CustomSortDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <CustomChecklistDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <CustomDatabase globalState={this.globalState} darkMode={this.state.darkMode} />
                    <HiddenCardList globalState={this.globalState} darkMode={this.state.darkMode} />
                    <CardIconDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                    <CardDataDialog globalState={this.globalState} darkMode={this.state.darkMode} />
                </div>
            </>
        );
    }
}
