import { GlobalState } from '../globalState';
import { Analytics } from './analytics';

declare const Android: any;
declare const Windows: any;

export class DownloadTools {
    /*
     * Based on FileSaver.js
     * A saveAs() FileSaver implementation.
     *
     * By Eli Grey, http://eligrey.com
     *
     * License : https://github.com/eligrey/FileSaver.js/blob/master/LICENSE.md (MIT)
     * source  : http://purl.eligrey.com/github/FileSaver.js
     */
    private static _SaveAs(blob: Blob, name: string, document: HTMLDocument) {
        if ('download' in HTMLAnchorElement.prototype) {
            const URL = window.URL || (window as any).webkitURL;
            const a = document.createElement('a');

            a.download = name;
            a.rel = 'noopener'; // tabnabbing

            a.href = URL.createObjectURL(blob);
            setTimeout(() => {
                URL.revokeObjectURL(a.href);
            }, 4e4); // 40s
            setTimeout(() => {
                this._Click(a, document);
            }, 0);
            return;
        }

        // Open a popup immediately do go around popup blocker
        // Mostly only available on user interaction and the fileReader is async so...
        let popup = open('', '_blank');
        if (popup) {
            popup.document.title = popup.document.body.innerText = 'downloading...';
        }

        const force = blob.type === 'application/octet-stream';
        const isSafari = /constructor/i.test((window as any).HTMLElement) || (window as any).safari;
        const isChromeIOS = /CriOS\/[\d]+/.test(navigator.userAgent);

        if ((isChromeIOS || (force && isSafari)) && typeof FileReader !== 'undefined') {
            // Safari doesn't allow downloading of blob URLs
            const reader = new FileReader();
            reader.onloadend = () => {
                let url: any = reader.result;
                url = isChromeIOS ? url : url.replace(/^data:[^;]*;/, 'data:attachment/file;');
                if (popup) {
                    popup.location.href = url;
                } else {
                    location = url;
                }
                popup = null;
            };
            reader.readAsDataURL(blob);
        } else {
            const URL = window.URL || (window as any).webkitURL;
            const url = URL.createObjectURL(blob);
            if (popup) {
                popup.location.href = url;
            } else {
                location.href = url;
            }
            popup = null;
            setTimeout(function () {
                URL.revokeObjectURL(url);
            }, 4e4);
        }
    }

    private static _Click(node: HTMLElement, document: HTMLDocument) {
        try {
            node.dispatchEvent(new MouseEvent('click'));
        } catch (e) {
            const evt = document.createEvent('MouseEvents');
            evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80, 20, false, false, false, false, 0, null);
            node.dispatchEvent(evt);
        }
    }

    public static DownloadAsync(data: string, fileName: string, globalState: GlobalState, extension: string, fileType?: string, copyToClipboard?: boolean): Promise<void> {
        const windowsAsAny = window as any;

        if (copyToClipboard) {
            navigator.clipboard.writeText(data).then(() => {
                // Do nothing
            });
        }

        // File system API
        if (fileType && windowsAsAny.showSaveFilePicker && navigator.userAgent.indexOf('OPR') === -1) {
            const options = {
                types: [
                    {
                        description: fileType,
                        accept: {
                            'text/plain': [extension]
                        }
                    }
                ]
            };

            return windowsAsAny.showSaveFilePicker(options).then(async (fileHandle: any) => {
                const writable = await fileHandle.createWritable();
                // Write the contents of the file to the stream.
                await writable.write(data);
                // Close the file and write the contents to disk.
                await writable.close();
            });
        }

        const title = fileName + extension;

        return new Promise((resolve, reject) => {
            // Win10
            if (
                typeof Windows !== 'undefined' &&
                typeof Windows.Storage !== 'undefined' &&
                typeof Windows.Storage.Pickers !== 'undefined'
            ) {
                const savePicker = new Windows.Storage.Pickers.FileSavePicker();
                savePicker.suggestedStartLocation = Windows.Storage.Pickers.PickerLocationId.documentsLibrary;
                savePicker.suggestedFileName = title;

                savePicker.fileTypeChoices.insert(globalState.translate('UrzaGathererFiles'), [
                    title.substring(title.length - 4)
                ] as any);

                savePicker
                    .pickSaveFileAsync()
                    .then(function (file: any) {
                        if (file) {
                            Windows.Storage.CachedFileManager.deferUpdates(file);
                            return Windows.Storage.FileIO.writeTextAsync(file, data).then(function () {
                                return Windows.Storage.CachedFileManager.completeUpdatesAsync(file);
                            });
                        } else {
                            reject();
                        }
                    })
                    .done(() => {
                        resolve();
                    });
                return;
            }

            // Android
            if (typeof Android !== 'undefined') {
                Android.saveFile(data, title);
                resolve();
                return;
            }

            // iOS
            if (Analytics.IsIOs()) {
                const file = new Blob([data], { type: 'application/octet-stream' });

                const reader = new FileReader();
                reader.onload = function () {
                    const bdata = btoa(reader.result as string);

                    const datauri = title + '@data:text/plain;base64,' + bdata;
                    setTimeout(() => {
                        const link = document.createElement('a');
                        link.setAttribute('href', datauri);
                        link.setAttribute('download', title);
                        link.target = '_self';
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        setTimeout(function () {
                            document.body.removeChild(link);
                            resolve();
                        }, 0);
                    }, 10);
                };
                reader.readAsBinaryString(file);
                return;
            }

            // Web
            const blob = new Blob([data], {
                type: 'application/octet-stream'
            });

            const navigator = window.navigator as any;
            if (navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(blob, title);
                resolve();
                return;
            }

            this._SaveAs(blob, title, document);
        });
    }
}
