import * as React from 'react';
import { ICard } from '../../entities/ICard';
import { Nullable } from '../../tools/nullable';
import { Observer } from '../../tools/observable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus, faMinus, faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { Collection } from '../../entities/collection';
import { CardRarity } from '../../entities/cardRarity';
import { PriceTools } from '../../tools/priceTools';
import { Button } from '../../controls/button';
import { ReservedList } from '../../entities/reservedList';
import { faClipboardList, faSun, faHelmetBattle, faGem, faBullseyeArrow } from '@fortawesome/pro-solid-svg-icons';
import { Icon, IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { StringHelpers } from '../../tools/stringHelpers';
import { GlobalState } from '../../globalState';
import { Deck } from '../../entities/deck';
import { CardFormat } from '../../entities/cardFormat';
import { Analytics } from '../../tools/analytics';
import { MobileTools } from '../../tools/mobileTools';
import { DeckCard } from '../../entities/deckCard';
import { CustomIcon } from '../../entities/customIcon';

interface ICardCardProps {
    globalState: GlobalState;
    card: ICard;
    width?: number;
    disableAnimation?: boolean;
    albumMode?: boolean;
    scanMode?: boolean;
    simulatorMode?: boolean;
    isCommander?: boolean;
    cardPageMode?: boolean;
    selectionMode?: boolean;
    collectionMode?: boolean;
    onClick?: (card: ICard) => void;
    onSelect?: (card: ICard) => void;
    onCollection?: (card: ICard) => void;
    onAddToCollection?: (card: ICard) => void;
    onRemoveFromCollection?: (card: ICard) => void;
    onAddOneFoilToCollection?: (card: ICard) => void;
    onRemoveOneFoilFromCollection?: (card: ICard) => void;
    onRemoveOneFromCollection?: (card: ICard) => void;
    onFullscreenMode?: (enabled: boolean) => void;
    onCollectionStateUpdated?: (card: ICard) => void;
    onFoil?: (card: ICard) => void;
    onSpecialFoil?: (card: ICard) => void;
    onDeck?: (card: ICard) => void;
    onAddToActiveDeck?: (card: ICard) => void;
    onSwapReprint?: (card: ICard) => void;
    onChangeIcon?: (card: ICard) => void;
    onSpecifics?: (card: ICard) => void;
    onChangeProxyState?: (card: ICard, state: boolean) => void;
    onChangeDeckProxyState?: (card: ICard, state: boolean) => void;
    onCommander?: (card: ICard) => void;
    onRemoveFromDeck?: (card: ICard) => void;
    onMoveInsideDeck?: (card: ICard) => void;
    onEDHRec?: (card: ICard) => void;
    onEbay?: (card: ICard) => void;
    onMCM?: (card: ICard) => void;
    onTCG?: (card: ICard) => void;
    onScryfall?: (card: ICard) => void;
    onOrderedFlag?: (card: ICard) => void;
    onTags?: (card: ICard) => void;
    deck?: Deck;
    deckInfoMode?: number;
    actionButton?: string;
    onAction?: () => void;
    showBackOnly?: boolean;
    forcedStyle?: React.CSSProperties;
}

export class CardCard extends React.Component<
    ICardCardProps,
    {
        displayBack: boolean;
        url: string;
        selected: boolean;
        selectionMode: boolean;
        collectionMode: boolean;
        fullscreenCard: boolean;
        alreadySetup: boolean;
        hover: boolean;
    }
> {
    private onSelectedCardObserver: Nullable<Observer<ICard>>;
    private onCardUpdatedObserver: Nullable<Observer<ICard>>;
    private onSelectModeObserver: Nullable<Observer<boolean>>;
    private onCollectionModeObserver: Nullable<Observer<boolean>>;
    private onContextMenuCommandObserver: Nullable<Observer<{ card: ICard; command: string }>>;

    private _unmounted = false;
    private _intervalId: number;
    private card: ICard;

    private _timer: Nullable<number> = null;

    private _rootRef: React.RefObject<HTMLDivElement>;

    constructor(props: ICardCardProps) {
        super(props);

        this.state = {
            displayBack: true,
            url: this.getCardUrl(props),
            selected: props.card.isSelected,
            collectionMode: this.props.collectionMode || false,
            selectionMode: this.props.selectionMode || false,
            fullscreenCard: false,
            alreadySetup: false,
            hover: false
        };

        this._rootRef = React.createRef();
    }

    getCardUrl(props: ICardCardProps) {
        const card = props.card;
        let picturePath: string;

        let usePng = false;

        if (props.card.forceStdQuality) {
            picturePath = 'https://collectocors.azurewebsites.net/Mid/';
        } else if (GlobalState.LoadBoolSettings('PNGPictures', false)) {
            usePng = true;
            picturePath = 'https://collectocors.azurewebsites.net/Png/';
        } else if (GlobalState.LoadBoolSettings('UltraHighQualityPictures', false)) {
            picturePath = 'https://collectocors.azurewebsites.net/High/';
        } else if (GlobalState.LoadBoolSettings('HighQualityPictures', true)) {
            picturePath = 'https://collectocors.azurewebsites.net/Mid/';
        } else {
            picturePath = 'https://collectocors.azurewebsites.net/Low/';
        }

        picturePath += card.picturePath.replace('\\', '/');

        if (usePng) {
            picturePath = picturePath.replace('.jpg', '.png');
        }

        if (StringHelpers.EndsWith(card.set.name, 'Collectors')) {
            picturePath = picturePath.replace("Collectors'", 'Collectors');
        }

        this.card = card;

        return picturePath;
    }

    manageError() {
        if (this._unmounted) {
            return;
        }
        if (!this.card.wasAlreadyLoadedOnce) {
            this.props.globalState.imageLoadingCount -= 1;
        }

        // Check high first
        if (this.state.url.indexOf('/Png/') > -1) {
            this.props.card.forceStdQuality = true;
            this.setState({
                url: this.state.url
                    .replace(
                        'https://collectocors.azurewebsites.net/Png/',
                        'https://collectocors.azurewebsites.net/Mid/'
                    )
                    .replace('.png', '.jpg')
            });
            return;
        }

        // Check mid first
        if (this.state.url.indexOf('/High/') > -1) {
            this.props.card.forceStdQuality = true;
            this.setState({ url: this.state.url.replace('/High/', '/Mid/') });
            return;
        }

        if (this.state.url.indexOf('/Low/') > -1) {
            this.props.card.forceStdQuality = true;
            this.setState({ url: this.state.url.replace('/Low/', '/Mid/') });
            return;
        }

        // Remove id
        let regex = /.+?(_\d+)\.jpg/;

        let matches = regex.exec(this.state.url);

        if (matches && matches.length) {
            this.setState({ url: this.state.url.replace(matches[1], '') });
            return;
        } else {
            regex = /.+?(\d+)\.jpg/;

            const matches = regex.exec(this.state.url);

            if (matches && matches.length) {
                this.setState({ url: this.state.url.replace(matches[1], '') });
                return;
            }
        }

        // Remove land number
        regex = /\s\(v. \d+\)\.jpg/;

        matches = regex.exec(this.state.url);

        if (matches && matches.length) {
            this.setState({ url: this.state.url.replace(matches[0], '.jpg') });
            return;
        }

        // Not found
        this.setState({ url: '/images/back.jpg' });
    }

    displayMainImage(controlId: string) {
        if (this._unmounted) {
            return;
        }
        this.setState({ displayBack: false });
        if (!this.card.wasAlreadyLoadedOnce) {
            this.props.globalState.imageLoadingCount -= 1;
            this.card.wasAlreadyLoadedOnce = true;
        }
        const host = document.querySelector('.card-card')! as HTMLDivElement;
        const img = document.getElementById(controlId) as HTMLImageElement;

        if (this.props.cardPageMode && this.props.card.size.isPlane) {
            this._intervalId = window.setInterval(() => {
                if (window.innerWidth < 1280) {
                    const ratio = img.naturalWidth / img.naturalHeight;

                    host.style.height = img.clientWidth / ratio + 'px';
                } else {
                    host.style.height = '';
                }
            }, 30);
        }
    }

    onLoaded(controlId: string) {
        if (this._unmounted) {
            return;
        }

        if (!this.props.width || this.props.disableAnimation) {
            this.displayMainImage(controlId);
            return;
        }

        setTimeout(() => {
            this.displayMainImage(controlId);
        }, 500);
    }

    UNSAFE_componentWillMount() {
        if (!this.card.wasAlreadyLoadedOnce) {
            this.props.globalState.imageLoadingCount += 1;
        }
        this.onSelectedCardObserver = this.props.globalState.onSelectedCardChanged.add((selectedCard) => {
            if (selectedCard === this.props.card) {
                this.setState({ selected: selectedCard.isSelected });
            }
        });

        this.onCardUpdatedObserver = this.props.globalState.onCardUpdated.add((selectedCard) => {
            if (selectedCard === this.props.card) {
                this.forceUpdate();
            }
        });

        this.onSelectModeObserver = this.props.globalState.onSelectMode.add((mode) => {
            this.setState({ selectionMode: mode });
        });

        this.onCollectionModeObserver = this.props.globalState.onCollectionMode.add((mode) => {
            this.setState({ collectionMode: mode });
        });

        this.onContextMenuCommandObserver = this.props.globalState.onContextMenuCommand.add((command) => {
            const card = this.props.card;

            if (card !== command.card) {
                return;
            }

            switch (command.command) {
                case 'select':
                    if (this.props.onSelect) {
                        this.props.onSelect(card);
                    }
                    break;
                case 'unselect':
                    this.navigate();
                    break;
                case 'collection':
                    if (this.props.onCollection) {
                        this.props.onCollection(card);
                    }
                    break;
                case 'addToCollection':
                    if (this.props.onAddToCollection) {
                        this.props.onAddToCollection(card);
                    }
                    break;
                case 'removeFromCollection':
                    if (this.props.onRemoveFromCollection) {
                        this.props.onRemoveFromCollection(card);
                    }
                    break;
                case 'foil':
                    if (this.props.onFoil) {
                        this.props.onFoil(card);
                    }
                    break;
                case 'specialFoil':
                    if (this.props.onSpecialFoil) {
                        this.props.onSpecialFoil(card);
                    }
                    break;
                case 'deck':
                    if (this.props.onDeck) {
                        this.props.onDeck(card);
                    }
                    break;
                case 'quickRemoveFromDeck':
                    if (this.props.onRemoveFromDeck) {
                        this.props.onRemoveFromDeck(card);
                    }
                    break;
                case 'moveInsideDeck':
                    if (this.props.onMoveInsideDeck) {
                        this.props.onMoveInsideDeck(card);
                    }
                    break;
                case 'edhrec':
                    if (this.props.onEDHRec) {
                        this.props.onEDHRec(card);
                    }
                    break;
                case 'swapReprint':
                    if (this.props.onSwapReprint) {
                        this.props.onSwapReprint(card);
                    }
                    break;
                case 'changeDeckProxyState':
                    if (this.props.onChangeDeckProxyState) {
                        this.props.onChangeDeckProxyState(card, !!command.option);
                    }
                    break;
                case 'changeProxyState':
                    if (this.props.onChangeProxyState) {
                        this.props.onChangeProxyState(card, !!command.option);
                    }
                    break;
                case 'changeIcon':
                    if (this.props.onChangeIcon) {
                        this.props.onChangeIcon(card);
                    }
                    break;
                case 'specifics':
                    if (this.props.onSpecifics) {
                        this.props.onSpecifics(card);
                    }
                    break;
                case 'commander':
                    if (this.props.onCommander) {
                        this.props.onCommander(card);
                    }
                    break;
                case 'ordered':
                    if (this.props.onOrderedFlag) {
                        this.props.onOrderedFlag(card);
                    }
                    break;
                case 'ebay':
                    if (this.props.onEbay) {
                        this.props.onEbay(card);
                    }
                    break;
                case 'mcm':
                    if (this.props.onMCM) {
                        this.props.onMCM(card);
                    }
                    break;
                case 'tcg':
                    if (this.props.onTCG) {
                        this.props.onTCG(card);
                    }
                    break;
                case 'tags':
                    if (this.props.onTags) {
                        this.props.onTags(card);
                    }
                    break;
                case 'scryfall':
                    if (this.props.onScryfall) {
                        this.props.onScryfall(card);
                    }
                    break;
            }
        });
    }

    componentWillUnmount() {
        if (this.state.displayBack && !this.card.wasAlreadyLoadedOnce) {
            this.props.globalState.imageLoadingCount -= 1;
        }

        if (this._intervalId) {
            window.clearInterval(this._intervalId);
        }

        this.props.globalState.onSelectedCardChanged.remove(this.onSelectedCardObserver);
        this.props.globalState.onCardUpdated.remove(this.onCardUpdatedObserver);
        this.props.globalState.onSelectMode.remove(this.onSelectModeObserver);
        this.props.globalState.onCollectionMode.remove(this.onCollectionModeObserver);
        this.props.globalState.onContextMenuCommand.remove(this.onContextMenuCommandObserver);

        this._unmounted = true;
    }

    shouldComponentUpdate(
        prop: ICardCardProps,
        nextState: {
            displayBack: boolean;
            url: string;
            alreadySetup: boolean;
        }
    ) {
        if (this.card !== prop.card) {
            const newUrl = this.getCardUrl(prop);
            nextState.displayBack = true;
            nextState.alreadySetup = nextState.url === newUrl;
            nextState.url = newUrl;
        } else if (nextState.displayBack) {
            if (!this.card.wasAlreadyLoadedOnce) {
                this.props.globalState.imageLoadingCount += 1;
            }
        }

        return true;
    }

    navigate() {
        if (!this.props.onClick) {
            return;
        }

        this.props.onClick(this.props.card);
    }

    addToActiveDeck() {
        if (this.props.onAddToActiveDeck) {
            this.props.onAddToActiveDeck(this.props.card);
            this.props.globalState.onHighlightFooterButtonRequired.notifyObservers('activeDeck');
        }
    }

    addOne() {
        if (this.props.onAddToCollection) {
            this.props.onAddToCollection(this.card);
            return;
        }
        this.card.count = Math.min(GlobalState.MaxCardCount, this.card.count + 1);
        if (this.card.otherCard) {
            this.props.globalState.onCardUpdated.notifyObservers(this.card.otherCard);
        }

        Collection.RegisterSave();
        this.forceUpdate();
        if (this.props.onCollectionStateUpdated) {
            this.props.onCollectionStateUpdated(this.card);
        }
    }

    removeOne() {
        if (this.props.onRemoveOneFromCollection) {
            this.props.onRemoveOneFromCollection(this.card);
            return;
        }
        const previous = this.card.count;
        this.card.count = Math.max(0, this.card.count - 1);
        if (this.card.otherCard) {
            this.props.globalState.onCardUpdated.notifyObservers(this.card.otherCard);
        }

        if (this.card.count === 0 && previous !== 0 && this.props.onRemoveFromCollection) {
            this.props.onRemoveFromCollection(this.card);
        } else {
            Collection.RegisterSave();
            this.forceUpdate();
            if (this.props.onCollectionStateUpdated) {
                this.props.onCollectionStateUpdated(this.card);
            }
        }
    }

    addOneFoil() {
        if (this.props.onAddOneFoilToCollection) {
            this.props.onAddOneFoilToCollection(this.card);
            return;
        }
        this.props.globalState.addOneFoil(this.card);

        Collection.RegisterSave();
        this.forceUpdate();
        if (this.props.onCollectionStateUpdated) {
            this.props.onCollectionStateUpdated(this.card);
        }
    }

    removeOneFoil() {
        if (this.props.onRemoveOneFoilFromCollection) {
            this.props.onRemoveOneFoilFromCollection(this.card);
            return;
        }
        const previous = this.card.count;
        this.props.globalState.removeOneFoil(this.card);

        if (this.card.count === 0 && previous !== 0 && this.props.onRemoveFromCollection) {
            this.props.onRemoveFromCollection(this.card);
        } else {
            Collection.RegisterSave();
            this.forceUpdate();
            if (this.props.onCollectionStateUpdated) {
                this.props.onCollectionStateUpdated(this.card);
            }
        }
    }

    prepareTextForTooltip(value: string) {
        let result = '';

        do {
            let nextMove = 0;
            let spaceIndex = 0;
            let returnIndex = 0;
            do {
                spaceIndex = value.indexOf(' ', nextMove);
                returnIndex = value.indexOf('\n', nextMove);
                nextMove = spaceIndex + 1;
            } while (spaceIndex < 40 && spaceIndex !== -1 && (spaceIndex < returnIndex || returnIndex === -1));

            if (spaceIndex > returnIndex && returnIndex > -1) {
                spaceIndex = returnIndex;
            }

            if (spaceIndex === -1) {
                result += value;
                break;
            }
            result += value.substring(0, spaceIndex).trim() + '\n';
            value = value.substring(spaceIndex).trim();
        } while (value.length);

        return result;
    }

    displayContextMenu(x: number, y: number) {
        if (this.props.simulatorMode || this.props.albumMode || this.props.cardPageMode || this.props.scanMode) {
            return false;
        }

        let top = y;
        let left = x;

        left = Math.min(window.innerWidth - 260, left);
        top = Math.min(window.innerHeight - 620, top);

        this.props.globalState.onContextMenuRequired.notifyObservers({
            left: left,
            top: top,
            card: this.props.card,
            deck: this.props.deck
        });

        return true;
    }

    onContextMenu(evt: React.MouseEvent<HTMLDivElement>) {
        evt.preventDefault();
        this.displayContextMenu(evt.pageX, evt.pageY);
    }

    fireLongPressEvent(x: number, y: number) {
        this.clearLongPressTimer();

        if (!this.displayContextMenu(x, y)) {
            return;
        }

        const that = this as any;

        // temporarily intercept and clear the next click
        this._rootRef.current!.addEventListener('touchend', function clearMouseUp(e) {
            that._rootRef.current!.removeEventListener('touchend', clearMouseUp, true);
            e.preventDefault();
            e.stopPropagation();
        }, true);
    }

    startLongPressTimer(e: React.TouchEvent<HTMLDivElement>) {
        if (!Analytics.IsIOs()) {
            return;
        }
        this.clearLongPressTimer();
        const x = e.touches[0].clientX;
        const y = e.touches[0].clientY;
        this._timer = window.setTimeout(() => this.fireLongPressEvent(x, y), 1000);
    }

    clearLongPressTimer() {
        if (!Analytics.IsIOs()) {
            return;
        }
        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = null;
        }
    }

    render() {
        const card = this.props.card;
        const simpleMode = this.props.cardPageMode || this.props.albumMode || this.props.simulatorMode;
        const displayActiveDeckIcon = this.props.globalState.displayActiveDeckIcon;
        const commandsOnHover = this.props.globalState.commandsOnHover && !MobileTools.IsMobile;

        const style: any = {
            transform: ''
        };

        if (card.size.isPlane && this.props.cardPageMode) {
            const host = document.querySelector('.client')!;
            const width = this.props.width
                ? this.props.width
                : Math.min(this.props.globalState.imageWidth, host.clientWidth - 30);
            const height = Math.ceil(width * 1.42);
            if (this.state.fullscreenCard) {
                style.objectPosition = 'center';
                style.transform = 'rotate(90deg) scale(' + (height / width) + ')';
            }
        }

        let backImageClass = 'card-card-image-back';

        if (this.props.width) {
            if (this.props.disableAnimation) {
                backImageClass += this.props.showBackOnly || this.state.displayBack ? '' : ' directNoAnim';
            } else {
                backImageClass += this.props.showBackOnly || this.state.displayBack ? ' inverted' : ' direct';
            }
        }

        if (this.state.selectionMode && !this.state.selected) {
            backImageClass += ' unselected';
        }

        const advancedTooltip = GlobalState.LoadBoolSettings('UseAdvancedTooltips', false);

        const cardsFromReservedList = this.props.globalState.showCardsFromReservedList && ReservedList.IsReserved(card);

        let totalEntryCount = 0;

        if (!this.props.deck && !simpleMode && this.props.globalState.deckIndicator) {
            totalEntryCount = card.totalEntryCount || 0;
        }

        const showCardPrice = this.props.globalState.showCardPrice && !this.props.scanMode;

        const showFoilIndicator = this.props.globalState.foilIndicator && (card.foilCount > 0);
        const showSpecialFoilIndicator = this.props.globalState.foilIndicator && (card.specialFoilCount > 0);

        if (this.state.alreadySetup) {
            setTimeout(() => {
                this.onLoaded('card' + card.id);
            }, 50);
        }

        let isInvalid = false;

        if (this.props.deck && this.props.deck.format && this.props.deck.format > 0) {
            isInvalid = card.validForList.indexOf(CardFormat.GetFormatByIndex(this.props.deck.format).name) === -1;
        }

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const transparentTags = this.props.globalState.transparentTags;

        const treatments: Array<{
            className: string,
            value: number,
            icon: IconProp,
            offset: number,
            intermediate: boolean
        }> = [];

        if (!simpleMode || this.props.albumMode && this.props.globalState.displayCollectionCountInAlbumView) {
            if (showFoilIndicator) {
                treatments.push({
                    className: 'card-card-foil',
                    value: card.foilCount,
                    icon: faSun as Icon,
                    offset: 0,
                    intermediate: showSpecialFoilIndicator
                });
            }

            if (showSpecialFoilIndicator) {
                treatments.push({
                    className: 'card-card-special-foil',
                    value: card.specialFoilCount,
                    icon: faGem as Icon,
                    offset: showFoilIndicator ? 1 : 0,
                    intermediate: false
                });
            }
        }

        let displayReprint = this.props.globalState.showReprintCount && card.reprints.length > 0;
        const foilInCollectionMode = this.card.canBeStandard && this.props.globalState.foilInCollectionMode && card.canBeFoil;
        let reprintCounts: number[] = [];
        let reprintCountValue = 0;

        if (displayReprint) {
            reprintCounts = card.reprints.filter(c => !c.isBackFace && card.set.isArtSeries === c.set.isArtSeries).map(c => c.count);
            displayReprint = reprintCounts.length > 0;

            if (displayReprint) {
                reprintCountValue = reprintCounts.reduce((a, b) => a + b);
                displayReprint = reprintCountValue > 0;
            }
        }

        return (
            <div
                style={this.props.forcedStyle}
                ref={this._rootRef}
                className={
                    'card-card' +
                    (card.isProxy || !!((card as DeckCard).isDeckProxy) ? ' proxy' : '') +
                    (card.count > 0 ? ' inCollection' : '') +
                    (!this.state.collectionMode && !this.props.cardPageMode ? ' activable' : '') +
                    (isInvalid ? ' card-deck-invalid' : '')
                }
                title={
                    (simpleMode && !this.props.albumMode)
                        ? ''
                        : advancedTooltip
                            ? (isInvalid ? this.props.globalState.translate('DeckInvalid') + '\n\n' : '') +
                            card.name + ` (${card.number})` +
                            '\n' +
                            card.set.name +
                            '\n' +
                            card.author +
                            '\n' +
                            `${CardRarity.Rarities[card.rarityId].name} - ${PriceTools.Format(
                                card.price,
                                this.props.globalState
                            )}${card.foilPrice && card.price !== card.foilPrice
                                ? ` (${PriceTools.Format(card.foilPrice, this.props.globalState)})`
                                : ''
                            }` +
                            '\n' +
                            '\n' +
                            card.type +
                            '\n' +
                            '\n' +
                            translate('Decks_') +
                            ': ' +
                            (card.getDeckString(this.props.globalState) || translate('NoneDeck')) +
                            '\n' +
                            '\n' +
                            this.prepareTextForTooltip(card.text)
                            : card.name
                }
                onPointerEnter={() => this.setState({ hover: true })}
                onPointerLeave={() => this.setState({ hover: false })}
                onContextMenu={(evt) => this.onContextMenu(evt)}
                onClick={(evt) => {
                    if (evt.buttons === 0 || evt.buttons === 1) {
                        this.navigate();
                    }
                }
                }
                onTouchStart={(evt) => this.startLongPressTimer(evt)}
                onTouchCancel={() => this.clearLongPressTimer()}
                onTouchEnd={() => this.clearLongPressTimer()}
                onTouchMove={() => this.clearLongPressTimer()}
            >
                {(this.props.showBackOnly ||
                    this.props.cardPageMode ||
                    (this.state.displayBack && !this.props.cardPageMode)) && (
                        <div className={backImageClass}>
                            <img src="/images/back.jpg" />
                        </div>
                    )}
                {(!this.props.albumMode || card.count === 0) && this.state.displayBack && !this.props.cardPageMode && (
                    <div className="card-card-name">{card.name}</div>
                )}
                {this.state.fullscreenCard && this.props.cardPageMode && <div className="card-card-image-background" />}
                {(!this.props.albumMode || card.count > 0) && (
                    <div
                        className={
                            'card-card-image-container' +
                            (this.state.fullscreenCard && this.props.width ? ' fullscreen' : '') +
                            ((this.props.showBackOnly || this.state.displayBack) &&
                                this.props.width &&
                                !this.props.disableAnimation
                                ? ' inverted'
                                : '') +
                            ((this.props.showBackOnly || this.state.displayBack) &&
                                this.props.width &&
                                this.props.disableAnimation
                                ? ' hidden'
                                : '')
                        }
                        onDoubleClick={() => {
                            if (window.innerWidth < 1280) {
                                return;
                            }
                            if (this.props.onFullscreenMode) {
                                this.props.onFullscreenMode(!this.state.fullscreenCard);
                            }
                            this.setState({ fullscreenCard: !this.state.fullscreenCard });
                        }}
                    >
                        <img
                            src={this.state.url}
                            id={'card' + card.id}
                            style={style}
                            className={
                                'card-card-image' +
                                (card.size.isPlane && !this.props.cardPageMode ? ' plane' : '') +
                                (this.state.selectionMode && !this.state.selected ? ' unselected' : '')
                            }
                            onError={() => this.manageError()}
                            onLoad={(_evt) => this.onLoaded('card' + card.id)}
                        />
                    </div>
                )}
                {(!simpleMode || this.props.albumMode && this.props.globalState.displayCollectionCountInAlbumView) && card.count > 0 && (
                    <div className={'card-card-count' + (transparentTags ? ' transparent-tag' : '') + (displayReprint ? ' box' : '')}>
                        <div className="card-card-count-text">{card.count}</div>
                    </div>
                )}
                {!simpleMode && displayReprint && (
                    <div className={'card-card-reprint' + (transparentTags ? ' transparent-tag' : '') + (card.count === 0 ? ' alone' : '')}>
                        <div className="card-card-reprint-text">{reprintCountValue}</div>
                    </div>
                )}
                {!simpleMode && showCardPrice && (
                    <div className={'card-card-price' + (transparentTags ? ' transparent-tag' : '')}>
                        <div className="card-card-price-text">
                            {PriceTools.Format(card.price, this.props.globalState) +
                                (card.foilPrice && card.foilPrice !== card.price
                                    ? ' / ' + PriceTools.Format(card.foilPrice, this.props.globalState)
                                    : '')}
                        </div>
                    </div>
                )}
                {!!this.props.deck && card.entryCount !== undefined && card.entryCount > 0 && (
                    <div className={'card-card-deck-count' + (transparentTags ? ' transparent-tag' : '')}>
                        <div className="card-card-deck-count-text">
                            {!this.props.deckInfoMode
                                ? card.entryCount
                                : this.props.deckInfoMode === 1
                                    ? card.entryCount - card.count
                                    : card.count}
                        </div>
                    </div>
                )}
                {totalEntryCount > 0 && (
                    <div className={'card-card-deck-count' + (transparentTags ? ' transparent-tag' : '')}>
                        <div className="card-card-deck-count-text">{totalEntryCount}</div>
                    </div>
                )}
                {!simpleMode && <div className={'card-card-border'
                    + (card.count > 0 ? (this.props.globalState.displayLargeCollectionIndicator && !this.props.globalState.displayLargeMissingCollectionIndicator ? ' inCollectionFull' : ' inCollection') : '')
                    + (card.count === 0 && this.props.globalState.displayLargeCollectionIndicator && this.props.globalState.displayLargeMissingCollectionIndicator ? ' inCollectionFull' : '')
                } />}
                {((!!this.props.deck && card.isDeckCommander) || this.props.isCommander) &&
                    this.props.deck &&
                    (!this.props.deck.format ||
                        this.props.deck.format === CardFormat.CommanderIndex ||
                        this.props.deck.format === CardFormat.DuelCommanderIndex ||
                        this.props.deck.format === CardFormat.BrawlIndex) && (
                        <div className={'card-card-commander'}>
                            <div className="card-card-commander-border" />
                            <div className={'card-card-commander-mark' + (this.props.isCommander ? ' simulator' : '')}>
                                <FontAwesomeIcon icon={faHelmetBattle as Icon} />
                            </div>
                        </div>
                    )}
                {!simpleMode && this.state.selected && <div className="card-card-select" />}
                {!simpleMode && this.state.selected && (
                    <div className="card-card-select-mark">
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                )}
                {
                    treatments.length > 0 && treatments.map((treatment, index) => {
                        return (
                            <div key={index} className={treatment.className + (treatment.offset ? ' offset' : '') + (treatment.intermediate ? ' intermediate' : '') + (transparentTags ? ' transparent-tag' : '')}
                                style={{
                                    transform: 'translateY(' + (treatment.offset > 0 ? ((treatment.offset - 1) * 20 + 19) : 0) + 'px)'
                                }}
                            >
                                <div className={treatment.className + '-text'}>
                                    {treatment.value}
                                    <FontAwesomeIcon icon={treatment.icon} className={treatment.className + '-icon'} />
                                </div>
                            </div>
                        );
                    })
                }
                {!simpleMode && card.icon !== 0 && (
                    <div className={'card-card-custom-icon' + (transparentTags ? ' transparent-tag' : '')}>
                        <div className="card-card-custom-icon-text">
                            <FontAwesomeIcon icon={CustomIcon.CustomIcons[card.icon].icon as IconDefinition} />
                        </div>
                    </div>
                )}
                {!simpleMode && cardsFromReservedList && (
                    <div className={'card-card-reserved' + (this.state.collectionMode && (!commandsOnHover || this.state.hover) ? ' up' : '') + (transparentTags ? ' transparent-tag' : '')}>
                        <div className="card-card-count-text">
                            <FontAwesomeIcon icon={faClipboardList as Icon} />
                        </div>
                    </div>
                )}
                {this.state.collectionMode && displayActiveDeckIcon && (!commandsOnHover || this.state.hover) && (
                    <div className={'card-card-active-deck'}
                        onClick={(evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                            this.addToActiveDeck();
                        }}
                    >
                        <FontAwesomeIcon icon={faBullseyeArrow as Icon} />
                    </div>
                )}
                {this.state.collectionMode && (!commandsOnHover || this.state.hover) && (
                    <div className={'card-card-collection' + (!foilInCollectionMode ? ' small' : '')}>
                        <div
                            className="card-card-collection-button"
                            onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                                this.addOne();
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <div
                            className="card-card-collection-button card-card-collection-minus"
                            onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                                this.removeOne();
                            }}
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </div>
                        {foilInCollectionMode && (
                            <>
                                <div
                                    className="card-card-collection-button foil"
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                        this.addOneFoil();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                    <FontAwesomeIcon icon={faSun} className='foil-indicator' />
                                </div>
                                <div
                                    className="card-card-collection-button card-card-collection-minus foil"
                                    onClick={(evt) => {
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                        this.removeOneFoil();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faMinus} />
                                    <FontAwesomeIcon icon={faSun} className='foil-indicator' />
                                </div>
                            </>
                        )}
                    </div>
                )}
                {!simpleMode && card.isOrdered && (
                    <div className={'card-card-ordered' + (transparentTags ? ' transparent-tag' : '')}>
                        <FontAwesomeIcon icon={faShoppingBasket} />
                    </div>
                )
                }
                {
                    this.props.actionButton !== undefined && (
                        <Button
                            tabIndex={1}
                            className="card-card-action"
                            globalState={this.props.globalState}
                            onClicked={() => this.props.onAction!()}
                        >
                            {this.props.actionButton}
                        </Button>
                    )
                }
            </div >
        );
    }
}
