import { Icon } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faEllipsisH, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Block } from '../../entities/block';
import { GlobalState } from '../../globalState';
import { PriceTools } from '../../tools/priceTools';

interface IBlockCardProps {
    globalState: GlobalState;
    block: Block;
    onDelete: () => void;
}

export class BlockCard extends React.Component<IBlockCardProps, { showPopup: boolean, activated: boolean }> {
    private blockDocumentHandler = false;
    private onClickHandler: () => void;

    constructor(props: IBlockCardProps) {
        super(props);

        this.state = { showPopup: false, activated: false };
        this.onClickHandler = this.onDocumentClick.bind(this);
    }

    onClick() {
        if (this.state.showPopup) {
            this.setState({ showPopup: false });
            return;
        }

        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
            index: -1,
            uniqueID: this.props.block.id
        });
    }

    onDocumentClick() {
        if (this.blockDocumentHandler) {
            this.blockDocumentHandler = false;
            return;
        }
        if (!this.state.showPopup) {
            return;
        }
        this.setState({ showPopup: false });
    }


    UNSAFE_componentWillMount() {
        document.addEventListener('click', this.onClickHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickHandler);
    }

    onEdit() {
        this.props.globalState.onEditBlockRequired.notifyObservers(this.props.block);
    }

    render() {
        const block = this.props.block;
        const total = block.totalCardCount;
        const inCollection = block.inCollectionCardCount;
        const value = block.totalPrice;

        let description = '';

        if (inCollection !== total) {
            description = `${inCollection} / ${total}`;
        } else {
            description = `${total}`;
        }

        description += ` ${this.props.globalState.translateWithPlural('cards', total)} - ${PriceTools.Format(
            value,
            this.props.globalState
        )}`;

        const complete = this.props.globalState.blockProgressAsCards ? block.inCollectionCardCount : block.sets.filter((s) => s.isComplete).length;
        const absolute = this.props.globalState.blockProgressAsCards ? block.totalCardCount : block.sets.length;

        const progressStyle = {
            width: (complete / absolute) * 100 + '%'
        };

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        return (
            <div className={'block-card' +
                (GlobalState.IsThereABackground ? ' glass' : '') +
                (this.state.activated ? ' activated' : '')}
                onPointerDown={(_evt) => {
                    this.setState({ activated: true });
                }}
                onPointerOut={(_evt) => {
                    this.setState({ activated: false });
                }}
                onPointerUp={(_evt) => {
                    this.setState({ activated: false });
                }}
                onClick={(evt) => {
                    if (evt.buttons === 0 || evt.buttons === 1) {
                        this.onClick();
                    }
                }
                }
            >
                <div className="block-card-logo">
                    <img src={block.isCustom ? 'images/blocks/Custom.png' : `images/blocks/${block.name.replace(':', '_')}.png`} />
                </div>
                <div className="block-card-data">
                    <div className="block-card-title">
                        <div
                            className={
                                'block-card-title-content' + (complete === absolute ? ' complete' : '')
                            }
                        >
                            {block.name}
                        </div>
                    </div>
                    <div className="block-card-desc">{description}</div>
                    <div className="block-card-progress-bar">
                        <div className="block-card-progress-value" style={progressStyle}></div>
                        <div className="block-card-progress-text">{`${complete} / ${absolute}`}</div>
                    </div>
                    {
                        block.isCustom &&
                        <div
                            className="block-card-title-commands"
                            onPointerDown={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                            }}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                                this.blockDocumentHandler = true;
                                this.setState({ showPopup: !this.state.showPopup });
                            }}
                        >
                            <div className="button">
                                <FontAwesomeIcon icon={faEllipsisH} />
                            </div>
                            {
                                this.state.showPopup &&
                                <div className="block-card-popup-host">
                                    <div className="block-card-title-commands-popup">
                                        <div className="block-card-title-command" onClick={() => this.onEdit()}>
                                            <div className="block-card-title-command-icon">
                                                <FontAwesomeIcon icon={faEdit as Icon} />
                                            </div>
                                            <div className="block-card-title-command-label">{translate('Edit')}</div>
                                        </div>
                                        <div className="block-card-title-command" onClick={() => this.props.onDelete()}>
                                            <div className="block-card-title-command-icon">
                                                <FontAwesomeIcon icon={faTrash as Icon} />
                                            </div>
                                            <div className="block-card-title-command-label">{translate('Delete')}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}
