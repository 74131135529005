import * as React from 'react';
import { GlobalState } from '../globalState';
import { Checkbox } from '../controls/checkbox';
import { Button } from '../controls/button';
import { Collection } from '../entities/collection';
import { PatreonTools, PatreonLevel } from '../tools/patreonTools';
import { Nullable } from '../tools/nullable';
import { Observer } from '../tools/observable';
import { OneDriveSyncProvider } from '../sync/oneDriveSyncProvider';
import { ISyncProvider } from '../sync/syncProvider';
import { DropBoxSyncProvider } from '../sync/dropBoxSyncProvider';
import { SettingsGroup } from '../controls/settingsGroup';
import { faBorderAll, faClipboardCheck, faDatabase, faDesktop, faGlobeEurope, faImage, faSort, faSync, faTags } from '@fortawesome/pro-solid-svg-icons';
import { faPatreon } from '@fortawesome/fontawesome-free-brands';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { CardLanguage } from '../entities/cardLanguage';
import { CardCondition } from '../entities/cardCondition';
import { CardGrading } from '../entities/cardGrading';
import { UrzaDirectSyncProvider } from '../sync/urzaDirectSyncProvider';
import { MobileTools } from '../tools/mobileTools';

require('../scss/settingsPage.scss');

interface ISettingsPageProps {
    globalState: GlobalState;
}

export class SettingsPage extends React.Component<ISettingsPageProps> {
    private _onSynchronizationChangedObserver: Nullable<Observer<void>>;
    private _onCancelImageDownloadRequiredObserver: Nullable<Observer<void>>;
    private _activeCardIndex = 0;

    constructor(props: ISettingsPageProps) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this._onSynchronizationChangedObserver = this.props.globalState.onSynchronizationChanged.add(() => {
            this.forceUpdate();
        });

        this._onCancelImageDownloadRequiredObserver = this.props.globalState.onCancelImageDownloadRequired.add(() => {
            this._activeCardIndex = Collection.Cards.length;
        });
    }

    componentWillUnmount() {
        this.props.globalState.onSynchronizationChanged.remove(this._onSynchronizationChangedObserver);
        this.props.globalState.onCancelImageDownloadRequired.remove(this._onCancelImageDownloadRequiredObserver);
    }

    renderDropdown(option: string, defaultValue: string, options: string[], onChange?: (_value: string) => void) {
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const gs = this.props.globalState;

        return (
            <div className="setting-content">
                <div className="setting-content-label">{translate(option)}</div>
                <div className="setting-content-control setting-dropdown">
                    <select
                        value={GlobalState.LoadSettings(option, defaultValue)}
                        onChange={(evt) => {
                            gs.storeSettings(option, evt.target.value);
                            this.forceUpdate();
                            if (onChange) {
                                onChange(evt.target.value);
                            }
                        }}
                    >
                        {options.map((option) => {
                            return (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        );
    }

    renderCheckbox(
        option: string,
        defaultValue: boolean,
        onChange?: (_value: boolean) => void,
        checkPatron = false,
        readOnly = false,
        storeKey?: string
    ) {
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const gs = this.props.globalState;

        return (
            <div className="setting-content">
                <div className="setting-content-label">{translate(option)}</div>
                <div className="setting-content-control setting-checkbox">
                    <Checkbox
                        globalState={gs}
                        readonly={readOnly}
                        isChecked={GlobalState.LoadBoolSettings(storeKey ?? option, defaultValue)}
                        onCheckedChanged={(value) => {
                            if (checkPatron) {
                                if (
                                    PatreonTools.PledgeLevel === PatreonLevel.None
                                ) {
                                    this.props.globalState.onError.notifyObservers(
                                        this.props.globalState.translate('OnlyForPatreon')
                                    );
                                    return;
                                }
                            }
                            gs.storeBoolSettings(storeKey ?? option, value);
                            if (onChange) {
                                onChange(value);
                            }
                            this.forceUpdate();
                        }}
                    ></Checkbox>
                </div>
            </div>
        );
    }

    renderButton(option: string, onClick: () => void, add: string = '', checkPatron = false) {
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const gs = this.props.globalState;

        return (
            <div className="setting-content ">
                <div className="setting-content-label">
                    {translate(option + 'Desc').replace(
                        '${0}',
                        Collection.SyncProvider ? Collection.SyncProvider.name : translate('SyncProvider')
                    ) + add}
                </div>
                <div className="setting-content-control">
                    <Button className="setting-content-control-button" globalState={gs} onClicked={() => {
                        if (checkPatron) {
                            if (
                                PatreonTools.PledgeLevel === PatreonLevel.None
                            ) {
                                this.props.globalState.onError.notifyObservers(
                                    this.props.globalState.translate('OnlyForPatreon')
                                );
                                return;
                            }
                        }
                        onClick();
                    }}>
                        {translate(option)}
                    </Button>
                </div>
            </div>
        );
    }

    processFile(files: FileList | File[], merge: boolean, onLoad: () => void, forceUnicode = false) {
        const fileToLoad = files[0];
        const extension = fileToLoad.name.substring(fileToLoad.name.length - 4).toLocaleLowerCase();

        const reader = new FileReader();

        this.props.globalState.onWaitRingRequired.notifyObservers(true);

        reader.onloadend = (evt: ProgressEvent) => {
            const target = evt.target as any;
            if (target && target.readyState === FileReader.DONE) {
                Collection.Import(target.result, merge, extension);

                if (!forceUnicode && Collection.Cards.filter(c => c.count > 0).length === 0 && Collection.DeckGroups.length === 0) {
                    this.processFile(files, merge, onLoad, true);
                    return;
                }
                this.props.globalState.onWaitRingRequired.notifyObservers(false);
                onLoad();
            }
        };

        reader.readAsText(fileToLoad, forceUnicode ? 'unicode' : undefined);
    }

    onFileLoad(changeEvent: React.ChangeEvent<HTMLInputElement>, merge: boolean, confirmMessage: Nullable<string>, onLoad: () => void) {
        const files = changeEvent.target.files;
        if (!files) {
            return;
        }

        if (confirmMessage) {
            this.props.globalState.showConfirmDialog(confirmMessage).then(confirm => {
                if (confirm) {
                    this.processFile(files, merge, onLoad);
                }
            });

            return;
        }

        this.processFile(files, merge, onLoad);
    }

    loadFileUsingFileSystemAPI(merge: boolean, onLoad: () => void) {
        const windowsAsAny = window as any;
        const pickerOpts = {
            types: [
                {
                    description: this.props.globalState.translate('UgsFile'),
                    accept: {
                        'application/urzagatherer': ['.ugs', '.csv', '.txt', '.ugs.txt']
                    }
                }
            ],
            excludeAcceptAllOption: true,
            multiple: false
        };

        windowsAsAny.showOpenFilePicker(pickerOpts).then((fileHandles: any) => {
            if (!fileHandles || fileHandles.length === 0) {
                return;
            }

            fileHandles[0].getFile().then((file: File) => {
                this.processFile([file], merge, onLoad);
            });
        }).catch(() => {
            // ignore error
        });
    }

    renderFileLoadButton(option: string, merge: boolean, confirmMessage: Nullable<string>, onLoad: () => void) {
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        const gs = this.props.globalState;
        const id = 'files' + (merge ? '-merge' : '-import');
        const windowsAsAny = window as any;

        // File system API
        if (windowsAsAny.showOpenFilePicker) {
            return this.renderButton(option, () => {
                if (confirmMessage) {
                    this.props.globalState.showConfirmDialog(confirmMessage).then(confirm => {
                        if (confirm) {
                            this.loadFileUsingFileSystemAPI(merge, onLoad);
                        }
                    });

                    return;
                }

                this.loadFileUsingFileSystemAPI(merge, onLoad);
            });
        }

        return (
            <div className="setting-content ">
                <div className="setting-content-label">{translate(option + 'Desc')}</div>
                <div className="setting-content-control">
                    <input
                        type="file"
                        id={id}
                        value={undefined}
                        onChange={(evt) => this.onFileLoad(evt, merge, confirmMessage, onLoad)}
                        className="setting-content-control-file-input"
                    />
                    <label htmlFor={id} className="setting-content-control-file-label">
                        <Button className="setting-content-control-button" doNotBlockEvents={true} globalState={gs}>
                            {translate(option)}
                        </Button>
                    </label>
                </div>
            </div>
        );
    }

    updatePrices() {
        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({ index: -5 });
    }

    updateCustomSort() {
        this.props.globalState.showCustomSortDialog().then();
    }

    updateCustomChecklist() {
        this.props.globalState.showCustomChecklistDialog().then();
    }

    updateCustomDatabase(extension: boolean) {
        this.props.globalState.showCustomDatabaseDialog(extension).then();
    }

    manageHiddenCardList() {
        this.props.globalState.showHiddenCardListDialog().then();
    }

    async pumpDownload(onlyCollection: boolean) {
        const list = onlyCollection ? Collection.Cards.filter(c => c.count > 0) : Collection.Cards;
        const total = list.length;
        while (this._activeCardIndex < total - 1) {
            const card = list[this._activeCardIndex];
            this.props.globalState.onProgressMessageUpdate.notifyObservers(`Downloading images...${((this._activeCardIndex / total) * 100).toFixed(2)}%`);
            this.props.globalState.onProgressValueUpdate.notifyObservers(this._activeCardIndex / total);
            this._activeCardIndex++;
            try {
                await fetch('https://collectocors.azurewebsites.net/Mid/' + card.picturePath, { mode: 'no-cors', method: 'GET' });
            } catch (e) {
                // Just ignore
            }
        }

        this.props.globalState.onProgressMessageUpdate.notifyObservers('');
        this.props.globalState.onWaitRingRequired.notifyObservers(false);
    }

    async downloadMidImages(onlyCollection: boolean) {
        if (
            PatreonTools.PledgeLevel === PatreonLevel.None
        ) {
            this.props.globalState.onError.notifyObservers(this.props.globalState.translate('OnlyForPatreon'));
            return;
        }
        this.props.globalState.onWaitRingRequired.notifyObservers(true);
        this._activeCardIndex = 0;

        this.pumpDownload(onlyCollection);
        this.pumpDownload(onlyCollection);
        this.pumpDownload(onlyCollection);
        this.pumpDownload(onlyCollection);
    }

    updateAccentColor() {
        if (
            PatreonTools.PledgeLevel === PatreonLevel.None
        ) {
            this.props.globalState.onError.notifyObservers(this.props.globalState.translate('OnlyForPatreon'));
            return;
        }

        const currentColor = GlobalState.LoadSettings('AccentColor', '#CC7529');
        this.props.globalState.showColorPicker(currentColor).then((color) => {
            this.props.globalState.storeSettings('AccentColor', color);
            this.props.globalState.onAccentColorChanged.notifyObservers(color);
        });
    }

    connectToSyncProvider(provider: ISyncProvider) {
        const translate = this.props.globalState.translate.bind(this.props.globalState);
        this.props.globalState.showConfirmDialog(translate('KeepLocalFile'), 'Yes', 'No').then((value) => {
            if (!value) {
                Collection.UserList = [];
                Collection.SaveLocalUserList(new Date('1/1/1970'));
                this.props.globalState.storeDateSettings(this.props.globalState.saveDateKey, new Date('1/1/1970'));
            } else {
                this.props.globalState.storeDateSettings(this.props.globalState.saveDateKey, new Date());
            }
            Collection.SyncProvider = provider;
            Collection.SyncProvider.loginAsync()
                .then(() => {
                    Collection.RefreshAsync();
                    this.forceUpdate();
                })
                .catch((err) => {
                    this.props.globalState.onError.notifyObservers(translate(err));
                });
        });
    }

    updateBackground() {
        if (
            PatreonTools.PledgeLevel === PatreonLevel.None) {
            this.props.globalState.onError.notifyObservers(this.props.globalState.translate('OnlyForPatreon'));
            return;
        }

        this.props.globalState.showCardImagePicker(Collection.Cards).then((response) => {
            if (!response.url) {
                return;
            }

            if (response.url === 'none') {
                this.props.globalState.storeSettings('BackgroundImage', '');
            } else {
                this.props.globalState.storeSettings('BackgroundImage', response.url);
            }

            this.props.globalState.onBackgroundImageChanged.notifyObservers();

            Collection.RegisterSave();
            this.forceUpdate();
        });
    }

    manageUsers() {
        this.props.globalState.onUserUpdated.addOnce((value) => {
            if (!value) {
                return;
            }

            Collection.SaveUserList(new Date());
            this.forceUpdate();
        });
        this.props.globalState.onUserRequired.notifyObservers();
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const userList = [''];

        if (Collection.UserList && Collection.UserList.length > 0) {
            userList.push(...Collection.UserList);
        }

        return (
            <div className="page">
                <div className="settings-page">
                    <div className="settings-page-container">
                        <SettingsGroup title={translate('ImageQuality')} globalState={this.props.globalState} icon={faImage}>
                            {
                                <div className="setting">
                                    {this.renderCheckbox(
                                        'HighQualityPictures',
                                        true,
                                        undefined,
                                        false,
                                        GlobalState.LoadBoolSettings('UltraHighQualityPictures', false) ||
                                        GlobalState.LoadBoolSettings('PNGPictures', false)
                                    )}
                                </div>
                            }
                            <div className="setting">
                                {this.renderCheckbox(
                                    'UltraHighQualityPictures',
                                    false,
                                    undefined,
                                    true,
                                    GlobalState.LoadBoolSettings('PNGPictures', false)
                                )}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('PNGPictures', false, undefined, true)}
                            </div>
                        </SettingsGroup>
                        <SettingsGroup title={translate('CardDisplay')} globalState={this.props.globalState} icon={faTags}>
                            <div className="setting">
                                {this.renderCheckbox('TransparentTags', false, (value) => {
                                    this.props.globalState.transparentTags = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('DisplayLargeCollectionIndicator', false, (value) => {
                                    this.props.globalState.displayLargeCollectionIndicator = value;
                                })}
                            </div>
                            {
                                this.props.globalState.displayLargeCollectionIndicator &&
                                <div className="setting">
                                    {this.renderCheckbox('DisplayLargeMissingCollectionIndicator', false, (value) => {
                                        this.props.globalState.displayLargeMissingCollectionIndicator = value;
                                    })}
                                </div>
                            }
                            <div className="setting">
                                {this.renderCheckbox('FoilInCollectionMode', false, (value) => {
                                    this.props.globalState.foilInCollectionMode = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('DeckIndicator', false, (value) => {
                                    this.props.globalState.deckIndicator = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('FoilIndicator', false, (value) => {
                                    this.props.globalState.foilIndicator = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('DisplayPrice', false, (value) => {
                                    this.props.globalState.showCardPrice = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('DisplayReprintCount', false, (value) => {
                                    this.props.globalState.showReprintCount = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('DisplayReservedList', false, (value) => {
                                    this.props.globalState.showCardsFromReservedList = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('DisplayCollectionCountInAlbumView', false, (value) => {
                                    this.props.globalState.displayCollectionCountInAlbumView = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('DisplayActiveDeckIcon', false, (value) => {
                                    this.props.globalState.displayActiveDeckIcon = value;
                                })}
                            </div>
                        </SettingsGroup>
                        <SettingsGroup title={translate('Display')} globalState={this.props.globalState} icon={faDesktop}>
                            <div className="setting">
                                {this.renderCheckbox('AutoDarkMode', false, (value) => {
                                    let finalState = GlobalState.LoadBoolSettings('DarkMode', false);
                                    if (value) {
                                        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
                                        if (darkModeMediaQuery.matches) {
                                            finalState = true;
                                        } else {
                                            const lightModeMediaQuery = window.matchMedia('(prefers-color-scheme: light)');
                                            if (lightModeMediaQuery.matches) {
                                                finalState = false;
                                            }
                                        }
                                    }
                                    this.props.globalState.refreshTheme(finalState);
                                })}
                            </div>
                            {
                                !GlobalState.LoadBoolSettings('AutoDarkMode', false) &&
                                <div className="setting">
                                    {this.renderCheckbox('DarkMode', false, (value) => {
                                        this.props.globalState.refreshTheme(value);
                                    })}
                                </div>
                            }
                            <div className="setting">{this.renderCheckbox('UseAdvancedTooltips', false)}</div>

                            <div className="setting">
                                {this.renderCheckbox('HideCompleted', false, (value) => {
                                    this.props.globalState.hideCompleted = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('HideZeroCardSet', false, (value) => {
                                    this.props.globalState.hideZeroCardSet = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('HideSplitCard', false, (value) => {
                                    this.props.globalState.hideSplitCard = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('HideTransformCard', false, (value) => {
                                    this.props.globalState.hideTransformCard = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('ShowExtrasAsSeparate', false, (value) => {
                                    this.props.globalState.separateExtras = value;
                                    location.reload();
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('DeckAsGroupList', false, (value) => {
                                    this.props.globalState.deckAsGroupList = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('BlockProgressAsCards', false, (value) => {
                                    this.props.globalState.blockProgressAsCards = value;
                                })}
                            </div>
                            {
                                MobileTools.IsMobile &&
                                <div className="setting">
                                        {this.renderDropdown('NumberOfColumns', '2', ['1', '2', '3', '4'], (value) => {
                                            this.props.globalState.numberOfColumns = parseInt(value);
                                            this.props.globalState.checkImageSize();
                                        })}
                                </div>
                            }
                        </SettingsGroup>

                        <SettingsGroup title={translate('DataGrid')} globalState={this.props.globalState} icon={faBorderAll}>

                            <div className="setting">{this.renderCheckbox('DataGridInsteadOfList', false)}</div>
                            <div className="setting">
                                {this.renderCheckbox('DataGridInsteadOfListForCollection', false)}
                            </div>
                            <div className="setting">{this.renderCheckbox('DataGridInsteadOfListForDecks', false)}</div>
                        </SettingsGroup>


                        <SettingsGroup title={translate('Confirmations')} globalState={this.props.globalState} icon={faClipboardCheck}>
                            <div className="setting">{this.renderCheckbox('WhenRemovingFromCollection', false)}</div>
                        </SettingsGroup>

                        <SettingsGroup title={translate('Misc')} globalState={this.props.globalState} icon={faSort}>
                            <div className="setting">
                                {this.renderButton('UpdatePrices', () => this.updatePrices())}
                            </div>
                            <div className="setting">
                                {this.renderButton('UpdateCustomSort', () => this.updateCustomSort())}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('UseFoilPriceWhenOwned', false, (value) => {
                                    GlobalState.UseFoilPriceWhenOwned = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderButton('UpdateCustomChecklist', () => this.updateCustomChecklist())}
                            </div>
                            <div className="setting">
                                {this.renderDropdown('DefaultLanguage', CardLanguage.CardLanguages[0], CardLanguage.CardLanguages)}
                            </div>
                            <div className="setting">
                                {this.renderDropdown('DefaultCondition', CardCondition.CardConditions[0].name, CardCondition.CardConditions.map(c => c.name))}
                            </div>
                            <div className="setting">
                                {this.renderDropdown('DefaultGrading', CardGrading.CardGradings[0], CardGrading.CardGradings)}
                            </div>
                        </SettingsGroup>

                        <SettingsGroup title={translate('Localization')} globalState={this.props.globalState} icon={faGlobeEurope}>
                            <div className="setting">
                                {this.renderDropdown('Language', this.props.globalState.needFrenchVersion ? 'FR' : 'EN', ['EN', 'FR'], () => {
                                    location.reload();
                                })}
                            </div>

                            <div className="setting">
                                {this.renderCheckbox('FrenchDisplay', false, (value) => {
                                    Collection.UseFrenchForTexts = value;
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('IgnoreForeignSets', false, () => location.reload())}
                            </div>
                            <div className="setting">{this.renderCheckbox('UseEuroInsteadOfDollar', false)}</div>
                        </SettingsGroup>

                        <SettingsGroup title='Patreon' globalState={this.props.globalState} icon={faPatreon as Icon}>
                            {!PatreonTools.IsPatreon && (
                                <div className="setting">
                                    {this.renderButton('PatreonLogin', () => {
                                        this.props.globalState.showQuestionDialog(translate('Token'), '', false, undefined, translate('TokenHelp')).then(token => {
                                            if (!token) {
                                                return;
                                            }
                                            token = token.trim();
                                            PatreonTools.Disconnect();
                                            this.props.globalState.storeSettings('PatreonToken', token);

                                            this.props.globalState.onWaitRingRequired.notifyObservers(true);
                                            PatreonTools.GetPledgeLevelAsync().then(() => {
                                                if (PatreonTools.PledgeLevel === PatreonLevel.None) {
                                                    this.props.globalState.onError.notifyObservers(translate('TokenNotValid'));
                                                    this.props.globalState.onWaitRingRequired.notifyObservers(false);
                                                    return;
                                                }

                                                this.props.globalState.onPatreonConnected.notifyObservers();
                                                this.forceUpdate();
                                                this.props.globalState.onWaitRingRequired.notifyObservers(false);
                                                this.props.globalState.onMessage.notifyObservers(translate('ThanksPatreon'));
                                            });
                                        });
                                    })}
                                </div>
                            )}
                            {PatreonTools.IsPatreon && (
                                <div className="setting">
                                    {this.renderButton(
                                        'Disconnect',
                                        () => {
                                            if (Collection.SyncProvider && Collection.SyncProvider.name === 'UrzaDirect') {
                                                this.props.globalState.workLocally = true;
                                                Collection.SyncProvider.disconnect();
                                                (Collection.SyncProvider as any) = null;
                                            }
                                            PatreonTools.Disconnect();
                                            this.props.globalState.storeSettings('PatreonToken', '');
                                            this.props.globalState.onPatreonDisconnected.notifyObservers();
                                            this.forceUpdate();
                                        }
                                    )}
                                </div>
                            )}
                            <div className="setting">
                                {this.renderButton('AccentColor', () => {
                                    this.updateAccentColor();
                                })}
                            </div>
                            <div className="setting">
                                {this.renderButton('BackgroundImage', () => {
                                    this.updateBackground();
                                })}
                            </div>
                            <div className="setting">
                                {this.renderButton('DownloadMidImages', () => {
                                    this.downloadMidImages(false);
                                })}
                            </div>
                            <div className="setting">
                                {this.renderButton('DownloadMidImagesFromCollection', () => {
                                    this.downloadMidImages(true);
                                })}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('DisplayLinkedSet', false, undefined, true)}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('SyncSettings', false, undefined, true, false, this.props.globalState.syncSettingsKey)}
                            </div>
                            <div className="setting">
                                {this.renderCheckbox('CommandsOnHover', false, (value) => {
                                    this.props.globalState.commandsOnHover = value;
                                }, true)}
                            </div>
                        </SettingsGroup>

                        <SettingsGroup title={Collection.SyncProvider ? Collection.SyncProvider.name : translate('SyncProvider')} globalState={this.props.globalState}
                            icon={Collection.SyncProvider ? Collection.SyncProvider.icon : faSync}
                        >
                            {!this.props.globalState.workLocally && (
                                <div className="setting">
                                    <div className="setting-content">
                                        <div className="setting-content-label">{translate('SynchronizationState')}</div>
                                        <div className="setting-content-control">
                                            {this.props.globalState.synchronizationInProgress &&
                                                translate('Synchronizing...')}
                                            {!this.props.globalState.synchronizationInProgress &&
                                                translate('Synchronized')}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!this.props.globalState.workLocally && (
                                <div className="setting">
                                    {this.renderButton('DisconnectSync', () => {
                                        this.props.globalState.workLocally = true;
                                        Collection.SyncProvider.disconnect();
                                        (Collection.SyncProvider as any) = null;
                                        this.forceUpdate();
                                    })}
                                </div>
                            )}
                            {this.props.globalState.workLocally && navigator.onLine && (
                                <div className="setting">
                                    {this.renderButton('ConnectOneDriveSync', () => {
                                        this.connectToSyncProvider(new OneDriveSyncProvider());
                                    })}
                                </div>
                            )}
                            {this.props.globalState.workLocally && navigator.onLine && (
                                <div className="setting">
                                    {this.renderButton('ConnectDropboxSync', () => {
                                        this.props.globalState.storeSettings('provider', 'Dropbox');
                                        this.connectToSyncProvider(new DropBoxSyncProvider());
                                    })}
                                </div>
                            )}
                            {this.props.globalState.workLocally && navigator.onLine && (
                                <div className="setting">
                                    {this.renderButton('ConnectUrzaDirectSync', () => {
                                        this.props.globalState.storeSettings('provider', 'UrzaDirect');
                                        this.connectToSyncProvider(new UrzaDirectSyncProvider());
                                    }, '', true)}
                                </div>
                            )}
                            {!this.props.globalState.workLocally && (
                                <div className="setting">
                                    {this.renderButton('GetFromCloud', () =>
                                        Collection.RefreshAsync().then(() => {
                                            this.props.globalState.onMessage.notifyObservers(
                                                translate('DownloadSuccessful')
                                            );
                                            this.props.globalState.onNotificationRequired.notifyObservers(
                                                translate('DownloadSuccessful')
                                            );
                                        })
                                    )}
                                </div>
                            )}
                            {!this.props.globalState.workLocally && (
                                <div className="setting">
                                    {this.renderButton('SendToCloud', () =>
                                        Collection.ForcePush().then(() => {
                                            this.props.globalState.onMessage.notifyObservers(
                                                translate('UploadSuccessful')
                                            );
                                            this.props.globalState.onNotificationRequired.notifyObservers(
                                                translate('UploadSuccessful')
                                            );
                                        })
                                    )}
                                </div>
                            )}
                        </SettingsGroup>

                        <SettingsGroup title={translate('Database')} globalState={this.props.globalState} icon={faDatabase}>
                            {
                                Collection.UserList.length > 0 &&
                                <div className="setting">
                                    {
                                            this.renderDropdown('CurrentUser', this.props.globalState.currentUser, userList, (value) => {
                                                this.props.globalState.currentUser = value;
                                            location.reload();
                                        })
                                    }
                                </div>
                            }
                            <div className="setting">
                                {this.renderButton('ManageUsers', () => this.manageUsers())}
                            </div>
                            <div className="setting">{this.renderCheckbox('ReadOnlyMode', false)}</div>
                            <div className="setting">
                                {this.renderButton('Save', () => {
                                    Collection.ExportAsync().then(() => {
                                        this.props.globalState.onMessage.notifyObservers(translate('SaveSuccessful'));

                                        this.props.globalState.onNotificationRequired.notifyObservers(
                                            translate('SaveSuccessful')
                                        );
                                    });
                                })}
                            </div>
                            <div className="setting">
                                {this.renderFileLoadButton('Load', false, this.props.globalState.translate('LoadConfirm'), () => {
                                    this.props.globalState.onMessage.notifyObservers(translate('LoadSuccessful'));
                                    this.props.globalState.onNotificationRequired.notifyObservers(
                                        translate('LoadSuccessful')
                                    );
                                })}
                            </div>
                            <div className="setting">
                                {this.renderFileLoadButton('Merge', true, null, () => {
                                    this.props.globalState.onMessage.notifyObservers(translate('MergeSuccessful'));
                                    this.props.globalState.onNotificationRequired.notifyObservers(
                                        translate('MergeSuccessful')
                                    );
                                })}
                            </div>
                            {navigator.serviceWorker !== undefined && navigator.serviceWorker.controller && (
                                <div className="setting">
                                    {this.renderButton('CleanCache', () => {
                                        navigator.serviceWorker.controller!.postMessage('clearcache');
                                        this.props.globalState.onMessage.notifyObservers(
                                            translate('CleanCacheSuccessful')
                                        );
                                        this.props.globalState.onNotificationRequired.notifyObservers(
                                            translate('CleanCacheSuccessful')
                                        );
                                    })}
                                </div>
                            )}
                            {navigator.serviceWorker !== undefined && navigator.serviceWorker.controller && (
                                <div className="setting">
                                    {this.renderButton('UpdateDB', async () => {
                                        const registration = await navigator.serviceWorker.getRegistration();

                                        if (registration) {
                                            registration.update().then(() => {
                                                this.props.globalState.onMessage.notifyObservers(
                                                    translate('DBUpdateSuccessful')
                                                );
                                            }).catch(() => {
                                                this.props.globalState.onError.notifyObservers(
                                                    translate('DBUpdateFailed')
                                                );
                                            });
                                        } else {
                                            this.props.globalState.onError.notifyObservers(
                                                translate('DBUpdateFailed')
                                            );
                                        }
                                    })}
                                </div>
                            )}
                            <div className="setting">
                                {this.renderButton('UpdateBlockDatabase', () => this.updateCustomDatabase(false))}
                            </div>
                            <div className="setting">
                                {this.renderButton('UpdateSetDatabase', () => this.updateCustomDatabase(true))}
                            </div>
                            <div className="setting">
                                {this.renderButton('UpdateHiddenCardList', () => this.manageHiddenCardList())}
                            </div>
                            <div className="setting">{this.renderCheckbox('IgnoreCommonCards', false, () => location.reload())}</div>
                            <div className="setting">{this.renderCheckbox('IgnoreUncommonCards', false, () => location.reload())}</div>
                            <div className="setting">{this.renderCheckbox('IgnoreRareCards', false, () => location.reload())}</div>
                            <div className="setting">{this.renderCheckbox('IgnoreMythicCards', false, () => location.reload())}</div>
                            <div className="setting">{this.renderCheckbox('IgnoreArtCards', false, () => location.reload())}</div>
                            <div className="setting">{this.renderCheckbox('IgnoreOversizedCards', false, () => location.reload())}</div>
                            <div className="setting">{this.renderCheckbox('IgnoreSubstituteCards', false, () => location.reload())}</div>
                            <div className="setting">{this.renderCheckbox('IgnoreMinigames', false, () => location.reload())}</div>
                            <div className="setting">{this.renderCheckbox('IgnoreSerialized', false, () => location.reload())}</div>
                        </SettingsGroup>
                    </div>
                </div>
            </div>
        );
    }
}
