import * as React from 'react';
import { PatreonTools } from '../tools/patreonTools';
import { faInfo, faTerminal } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { GlobalState } from '../globalState';

require('../scss/aboutPage.scss');

interface IAboutPageProps {
    globalState: GlobalState;
}

export class AboutPage extends React.Component<IAboutPageProps, { showConsole: boolean, showDebugInfo: boolean }> {
    constructor(props: IAboutPageProps) {
        super(props);

        this.state = { showConsole: false, showDebugInfo: false };

        this.props.globalState.onCustomMenuRequired.notifyObservers([
            {
                label: 'Console',
                action: () => {
                    this.props.globalState.onBlurRequired.notifyObservers(true);
                    this.setState({ showConsole: true });
                },
                icon: faTerminal as Icon
            },
            {
                label: this.props.globalState.translate('DebugInfo'),
                action: () => {
                    this.props.globalState.onBlurRequired.notifyObservers(true);
                    this.setState({ showDebugInfo: true });
                },
                icon: faInfo as Icon
            }
        ]);
    }

    componentWillUnmount() {
        this.props.globalState.onBlurRequired.notifyObservers(false);
    }

    componentDidMount() {
        // nothing to do
    }

    render() {
        const translate = this.props.globalState.translate.bind(this.props.globalState);

        const debugInfos = [
            `Service worker: ${'serviceWorker' in navigator && location.href.indexOf('localhost') === -1 ? translate('Yes') : translate('No')}`,
            `Last sync date: ${GlobalState.LoadDateSettings(this.props.globalState.saveDateKey, new Date('1/1/1970'))}`,
            `Online mode: ${!this.props.globalState.workLocally ? translate('Yes') : translate('No')}`,
            `Provider: ${GlobalState.LoadSettings('provider')}`,
            `Logged Once: ${GlobalState.LoadBoolSettings('loggedOnce', false) ? translate('Yes') : translate('No')}`
        ];

        return (
            <div className="page">
                <div className="about-page">
                    <div className="part-1">
                        <div className="about-logo">
                            <img id="about-img" className="about-img" src="/images/splashscreen.png" />
                        </div>
                        <div className="about-version">v13.1.4</div>
                        {PatreonTools.IsPatreon && <div className="about-patreon">{translate('ThanksPatreon')}</div>}
                        <div className="about-description">
                            {translate('AboutUrzaGatherer1')}
                            <br></br>
                            {translate('AboutUrzaGatherer2')}
                        </div>
                        {!PatreonTools.IsPatreon && (
                            <div className="about-code">
                                <div className="about-oss-title">
                                    {translate('PatreonTopic')}
                                    <br />
                                </div>
                                <a href={'https://www.patreon.com/urzagatherer'} target="_blank" rel="noreferrer">
                                    Patreon
                                </a>
                            </div>
                        )}
                        <div className="about-code">
                            <div className="about-oss-title">{translate('CodeBy')}</div>
                            <a href="https://www.twitter.com/deltakosh" target="_blank" rel="noreferrer">
                                Deltakosh
                            </a>
                        </div>
                        <div className="about-code">
                            <div className="about-oss-title">{translate('LogoBy')}</div>
                            <a href="https://www.twitter.com/PatrickCRyan" target="_blank" rel="noreferrer">
                                Patrick Ryan
                            </a>
                        </div>
                        <div className="about-tweet">
                            <div className="about-tweet-title">{translate('SupportTheProject')}</div>
                            <a href={'https://www.deltakosh.com/urzagatherer'} target="_blank" rel="noreferrer">
                                Main forum
                            </a>
                            <br />
                            <a
                                href={`http://twitter.com/home?status=${encodeURIComponent(
                                    // eslint-disable-next-line max-len
                                    "I'm a fan of #urzagatherer. It is a great tool for #mtg players and collectors! https://www.urzagatherer.app"
                                )}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Twitter
                            </a>
                        </div>
                    </div>
                    <div className="part-2">
                        <div className="about-patrons">
                            <div className="about-oss-title">{translate('ThanksTo')}</div>
                            <div>Aaron Hubbard</div>
                            <div>Adrien Larminaux</div>
                            <div>AJ Foremny</div>
                            <div>Alexis Coiffard</div>
                            <div>Andrew Bennett</div>
                            <div>Angel Cruz</div>
                            <div>Ansel</div>
                            <div>Austin Brown</div>
                            <div>Dax</div>
                            <div>DemnoTheThird</div>
                            <div>Donny Deever</div>
                            <div>DP Durlston-Powell</div>
                            <div>Eric</div>
                            <div>Fate</div>
                            <div>Floriane Aujoux</div>
                            <div>JagmanJohn</div>
                            <div>Jai Alai</div>
                            <div>Jean-Paul Begue</div>
                            <div>Justin Phillips</div>
                            <div>Karl McPhee</div>
                            <div>Kevin Dupeu</div>
                            <div>Luke Lasecke</div>
                            <div>Marius Oudinet</div>
                            <div>Marco Garrido Torres</div>
                            <div>Matt Klosterman</div>
                            <div>Natsuru-san</div>
                            <div>Paul McCabe</div>
                            <div>PGA</div>
                            <div>Post-it</div>
                            <div>Pyxel81</div>
                            <div>RaelinGarr</div>
                            <div>Rian Farfromson</div>
                            <div>Sébastien Guilminot</div>
                            <div>Sébastien Karras</div>
                            <div>Sébastien Rouxeau</div>
                            <div>Stefan Leder</div>
                            <div>Stephan Matiasch</div>
                            <div>Stéphane Breton</div>
                            <div>Steve Turner</div>
                            <div>Steven Schutter</div>
                            <div>Tajacama</div>
                            <div>The Soviet Plague Doctor</div>
                            <div>Thomas Eubank</div>
                            <div>Thomas McBain</div>
                            <div>Veituselah</div>
                            <div>VirtualBlackCat</div>
                        </div>
                        <div className="about-oss">
                            <div className="about-oss-title">{translate('CardScans')}</div>
                            <a
                                className="oss-link"
                                href="https://www.slightlymagic.net/forum/viewforum.php?f=15"
                                target="_blank"
                                rel="noreferrer"
                            >
                                CCGHQ
                            </a>
                            <a className="oss-link" href="https://www.scryfall.com/" target="_blank" rel="noreferrer">
                                Scryfall
                            </a>
                        </div>
                        <div className="about-oss">
                            <div className="about-oss-title">{translate('Thanks')}</div>
                            <a
                                className="oss-link"
                                href="https://www.cardmarket.com/en/Magic"
                                target="_blank"
                                rel="noreferrer"
                            >
                                CardMarket
                            </a>
                            <a className="oss-link" href="https://www.scryfall.com/" target="_blank" rel="noreferrer">
                                Scryfall
                            </a>
                            <a className="oss-link" href="https://www.tcgplayer.com/" target="_blank" rel="noreferrer">
                                TCGPlayer
                            </a>
                        </div>
                        <div className="about-oss">
                            <div className="about-oss-title">{translate('OSS')}</div>
                            <a className="oss-link" href="https://fontawesome.com/" target="_blank" rel="noreferrer">
                                Font Awesome
                            </a>
                            <a className="oss-link" href="https://reactjs.org/" target="_blank" rel="noreferrer">
                                React
                            </a>
                            <a
                                className="oss-link"
                                href="https://react-popup.elazizi.com/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                React-Popup
                            </a>
                        </div>
                        <div className="about-trademarks">
                            <div className="about-trademarks-title">{translate('Trademarks')}</div>
                            {translate('TrademarksText')}
                        </div>
                        <div className="whatsnew">
                            <div className="whatsnew-title">{translate('Whatsnew')}</div>
                            {this.props.globalState.whatsNew.map((wn, i) => {
                                return (
                                    <div
                                        key={'about' + i}
                                        className="whatsnew-entry"
                                        dangerouslySetInnerHTML={{
                                            __html: wn
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    {this.state.showConsole && (
                        <div
                            className="console-container"
                            onClick={() => {
                                this.props.globalState.onBlurRequired.notifyObservers(false);
                                this.setState({ showConsole: false });
                            }}
                        >
                            <div className="console">
                                {this.props.globalState.logs.map((log, i) => {
                                    return (
                                        <div className="log" key={'log' + i}>
                                            {'> ' + log}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {this.state.showDebugInfo && (
                        <div
                            className="console-container"
                            onClick={() => {
                                this.props.globalState.onBlurRequired.notifyObservers(false);
                                this.setState({ showDebugInfo: false });
                            }}
                        >
                            <div className="console">
                                {debugInfos.map((info, i) => {
                                    return (
                                        <div className="log" key={i}>
                                            {info}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
