import * as React from 'react';
import { Collection } from '../../entities/collection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTrashAlt, faEdit, faClone, faImage } from '@fortawesome/free-solid-svg-icons';
import { faFeather } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { GUID } from '../../tools/guid';
import { List } from '../../entities/list';
import { ListGroup } from '../../entities/listGroup';
import { SearchData, GlobalState } from '../../globalState';
import * as ReactDOM from 'react-dom/client';

interface IListCardProps {
    globalState: GlobalState;
    list: List;
    listGroup: ListGroup;
    onDelete: () => void;
    onEdit: () => void;
    onClone: () => void;
}

export class ListCard extends React.Component<IListCardProps, { showPopup: boolean; activated: boolean }> {
    private onClickHandler: () => void;
    private popupAnchorRef: React.RefObject<HTMLInputElement>;

    constructor(props: IListCardProps) {
        super(props);

        this.state = { showPopup: false, activated: false };

        this.onClickHandler = this.onDocumentClick.bind(this);

        this.popupAnchorRef = React.createRef();
    }

    onClick(_evt: React.MouseEvent<HTMLDivElement>) {
        if (_evt.buttons !== 0 && _evt.buttons !== 1) {
            _evt.preventDefault();
            _evt.stopPropagation();
            return;
        }
        const list = this.props.list;
        const searchData = new SearchData();
        (searchData as any).tags = list.Tags;
        this.props.globalState.onSelectedMenuIndexChanged.notifyObservers({
            index: -3,
            searchData: searchData,
            title: list.Name
        });
    }

    UNSAFE_componentWillMount() {
        document.addEventListener('click', this.onClickHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickHandler);
    }

    onDocumentClick() {
        if (!this.state.showPopup) {
            return;
        }
        this.setState({ showPopup: false });
        this.hidePopup();
    }

    editList() {
        this.props.onEdit();
    }

    editNotes() {
        const list = this.props.list;
        this.props.globalState
            .showQuestionDialog(this.props.globalState.translate('Notes'), list.Notes, true)
            .then((notes) => {
                if (notes !== undefined) {
                    list.Notes = notes;
                    Collection.RegisterSave();
                }
            });
    }

    deleteList() {
        const list = this.props.list;
        const listGroup = this.props.listGroup;

        this.props.globalState
            .showConfirmDialog(this.props.globalState.translate('ListDeleteConfirm'))
            .then((value) => {
                if (!value) {
                    return;
                }
                let index = Collection.Lists.indexOf(list);

                if (index > -1) {
                    Collection.Lists.splice(index, 1);
                }

                index = listGroup.Lists.indexOf(list);

                if (index > -1) {
                    listGroup.Lists.splice(index, 1);

                    if (listGroup.Lists.length === 0) {
                        index = Collection.ListGroups.indexOf(listGroup);
                        Collection.ListGroups.splice(index, 1);
                    }
                }

                Collection.SaveUserStore(new Date());
                this.props.onDelete();
            });
    }

    cloneList() {
        const list = this.props.list;
        const listGroup = this.props.listGroup;

        const clone: List = JSON.parse(JSON.stringify(list));
        clone.Name = this.props.globalState.translate('CopyOf') + clone.Name;
        clone.UniqueID = GUID.Generate();
        listGroup.Lists.push(clone);

        Collection.Lists.push(clone);

        Collection.SaveUserStore(new Date());
        this.props.onClone();
    }

    renderPopup(x: number, y: number) {
        const popupStyle = {
            top: y + 'px',
            left: x + 'px'
        };

        const translate = this.props.globalState.translate.bind(this.props.globalState);
        return (
            <div className="list-card-title-commands-popup" style={popupStyle}>
                <div className="list-card-title-command" onClick={() => this.editList()}>
                    <div className="list-card-title-command-icon">
                        <FontAwesomeIcon icon={faEdit} />
                    </div>
                    <div className="list-card-title-command-label">{translate('Edit')}</div>
                </div>
                <div className="list-card-title-command" onClick={() => this.editNotes()}>
                    <div className="list-card-title-command-icon">
                        <FontAwesomeIcon icon={faFeather as Icon} />
                    </div>
                    <div className="list-card-title-command-label">{translate('Notes')}</div>
                </div>
                <div className="list-card-title-command" onClick={() => this.setBackground()}>
                    <div className="list-card-title-command-icon">
                        <FontAwesomeIcon icon={faImage} />
                    </div>
                    <div className="list-card-title-command-label">{translate('ChangeBackground')}</div>
                </div>
                <div className="list-card-title-command" onClick={() => this.cloneList()}>
                    <div className="list-card-title-command-icon">
                        <FontAwesomeIcon icon={faClone} />
                    </div>
                    <div className="list-card-title-command-label">{translate('Clone')}</div>
                </div>
                {
                    <div className="list-card-title-command" onClick={() => this.deleteList()}>
                        <div className="list-card-title-command-icon">
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </div>
                        <div className="list-card-title-command-label">{translate('Delete')}</div>
                    </div>
                }
            </div>
        );
    }

    managePopup(evt?: React.MouseEvent<HTMLDivElement>) {
        const rootElement = document.querySelector('.list-page-popup-host')!;

        evt?.stopPropagation();
        evt?.preventDefault();

        if (!this.state.showPopup && evt) {
            const client = document.querySelector('.page')!;
            const popupHeight = 38 * 5;

            const target = this.popupAnchorRef.current!.getBoundingClientRect();
            const ownClient = client.getBoundingClientRect();

            const x = (target.left - ownClient.left) - 110;
            let y = (target.top - ownClient.top) + 32;

            if (popupHeight + y > client.clientHeight) {
                y = client.clientHeight - popupHeight - 5;
            }

            let root: ReactDOM.Root;
            if ((rootElement as any).root) {
                root = (rootElement as any).root;
            } else {
                root = ReactDOM.createRoot(rootElement);
                (rootElement as any).root = root;
            }
            root.render(this.renderPopup(x, y));

            this.setState({ showPopup: true });
            return;
        } else {
            this.hidePopup();
            this.setState({ showPopup: false });
        }
    }

    hidePopup() {
        const rootElement = document.querySelector('.list-page-popup-host')!;
        if ((rootElement as any).root) {
            (rootElement as any).root.unmount();
            (rootElement as any).root = undefined;
        }
    }

    setBackground() {
        const list = this.props.list;
        this.props.globalState.showCardImagePicker(List.GetCards(list)).then((response) => {
            if (!response.url) {
                return;
            }

            if (response.url === 'none') {
                list.backgroundUrl = '';
            } else {
                list.backgroundUrl = response.url;
            }

            Collection.RegisterSave();
            this.forceUpdate();
        });
    }

    render() {
        const list = this.props.list;
        const style = {
            outline: '1px solid ' + list.ColorCode
        };

        const titleStyle = {
            background: list.ColorCode
        };

        const valueStyle = {
            color: list.ColorCode
        };

        const translate = this.props.globalState.translate.bind(this.props.globalState);

        let tags = list.Tags ? list.Tags.join(', ') : '';

        if (tags.length > 75) {
            tags = tags.substr(0, 74) + '...';
        }

        return (
            <>
                {this.state.showPopup && (
                    <div className="list-card-blocker" />
                )}
                <div
                    className={'list-card' + (this.state.activated ? ' activated' : '') + (GlobalState.IsThereABackground ? ' glass' : '')}
                    title={list.Notes}
                    onPointerDown={(_evt) => {
                        this.setState({ activated: true });
                    }}
                    onPointerOut={(_evt) => {
                        this.setState({ activated: false });
                    }}
                    onPointerUp={(_evt) => {
                        this.setState({ activated: false });
                    }}
                    style={style}
                    onClick={(evt) => this.onClick(evt)}
                >
                    <div className="list-card-title" style={titleStyle}>
                        <div className="list-card-title-label">{list.Name}</div>
                        <div
                            className="list-card-title-commands"
                            onPointerDown={(evt) => {
                                evt.stopPropagation();
                                evt.preventDefault();
                            }}
                            onClick={(evt) => {
                                this.managePopup(evt);
                            }}
                        >
                            <div className="button">
                                <FontAwesomeIcon icon={faEllipsisH} />
                            </div>
                            <div className="list-card-popup-host" ref={this.popupAnchorRef}>
                            </div>
                        </div>
                    </div>
                    {list.backgroundUrl && (
                        <div className="list-background-container">
                            <img src={list.backgroundUrl} className="list-background" />
                        </div>
                    )}
                    <div
                        className={'list-card-list-tags' + (list.backgroundUrl ? ' list-text-shadow list-white-text' : '')}
                    >
                        {tags}
                    </div>
                    <div
                        className={'list-card-list-label' + (list.backgroundUrl ? ' list-text-shadow list-white-text' : '')}
                    >
                        {translate('InCollection')}
                    </div>
                    <div
                        className={'list-card-list-cards' + (list.backgroundUrl ? ' list-text-shadow' : '')}
                        style={valueStyle}
                    >
                        {List.GetCards(list).filter(c => !c.isBackFace).length}
                    </div>
                </div>
            </>
        );
    }
}
