import * as React from 'react';
import { GlobalState } from '../globalState';
import { ContextMenuItem } from './contextMenuItem';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface ICustomMenuProps {
    globalState: GlobalState;
    darkMode: boolean;
}

export interface ICustomMenuDefinition {
    label: string;
    action?: () => void;
    icon?: IconDefinition;
}

export class CustomMenu extends React.Component<ICustomMenuProps, { visible: boolean }> {
    private customMenus: ICustomMenuDefinition[];

    constructor(props: ICustomMenuProps) {
        super(props);

        this.state = { visible: false };
    }

    UNSAFE_componentWillMount() {
        this.props.globalState.onCustomMenuDisplayRequired.add((_options) => {
            this.setState({ visible: true });
        });

        this.props.globalState.onCustomMenuRequired.add((customMenus) => {
            this.customMenus = customMenus;
        });

        this.props.globalState.onSelectedMenuIndexChanged.add(() => {
            this.setState({
                visible: false
            });
        });
    }

    componentDidUpdate() {
        const contextMenu = document.querySelector('.contextmenu') as HTMLDivElement;

        if (!contextMenu) {
            return;
        }
        contextMenu.style.transition = '';
        contextMenu.style.transform = 'translateY(25px)';
        contextMenu.style.opacity = '0';

        setTimeout(() => {
            contextMenu.style.transition = 'transform 150ms, opacity 150ms';
            contextMenu.style.transform = 'translateY(0px)';
            contextMenu.style.opacity = '1';
        });
    }

    close() {
        this.props.globalState.onCustomMenuClosed.notifyObservers();
        this.setState({ visible: false });
    }

    render() {
        return (
            this.state.visible &&
            this.customMenus !== null && (
                <div className="contextmenu-container" onClick={() => this.close()}>
                    <div
                        className={'contextmenu flat' + (this.props.darkMode ? ' dark' : '')}
                        style={{
                            transform: 'translateY(25px)',
                            transition: '',
                            opacity: 0,
                            bottom: '65px',
                            right: '0px'
                        }}
                    >
                        {this.customMenus.map((menu, i) => {
                            return (
                                <ContextMenuItem
                                    key={menu.label + i}
                                    globalState={this.props.globalState}
                                    onClicked={() => {
                                        if (menu.action) {
                                            menu.action();
                                        }
                                        this.close();
                                    }}
                                    label={menu.label}
                                    icon={menu.icon}
                                />
                            );
                        })}
                    </div>
                </div>
            )
        );
    }
}
