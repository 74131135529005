import * as React from 'react';
import { GlobalState } from '../globalState';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IContextMenuItemProps {
    globalState: GlobalState;
    onClicked?: () => void;
    className?: string;
    label?: string;
    icon?: IconDefinition;
}

export class ContextMenuItem extends React.Component<IContextMenuItemProps> {
    constructor(props: IContextMenuItemProps) {
        super(props);
    }

    onClick(evt: React.MouseEvent<HTMLDivElement>) {
        if (this.props.onClicked) {
            evt.preventDefault();
            evt.stopPropagation();
            this.props.onClicked();
        }
    }

    render() {
        if (!this.props.icon || !this.props.label) {
            return <div className="contextmenu-item-separator" />;
        }

        return (
            <div className="contextmenu-item" onClick={(evt) => this.onClick(evt)}>
                <div className="contextmenu-item-icon">{<FontAwesomeIcon icon={this.props.icon} />}</div>
                <div className="contextmenu-item-label">{this.props.label}</div>
            </div>
        );
    }
}
