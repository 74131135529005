import * as React from 'react';
import { GlobalState } from '../globalState';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-labels';

interface IPieChartProps {
    globalState: GlobalState;
    className: string;
    labels: string[];
    colors: string[];
    numbers: number[];
    title: string;
}

export class PieChart extends React.Component<IPieChartProps> {
    constructor(props: IPieChartProps) {
        super(props);
    }

    render() {
        const darkMode = GlobalState.LoadBoolSettings('DarkMode', false);
        const borderColors: string[] = [];

        for (let index = 0; index < this.props.colors.length; index++) {
            borderColors.push(darkMode ? '#333333' : '#EEEEEE');
        }

        const data: {
            labels: string[];
            datasets: {
                data: number[];
                backgroundColor: string[];
                hoverBackgroundColor: string[];
                borderColor: string[];
            }[];
        } = {
            labels: this.props.labels,
            datasets: [
                {
                    data: this.props.numbers,
                    backgroundColor: this.props.colors,
                    hoverBackgroundColor: this.props.colors,
                    borderColor: borderColors
                }
            ]
        };

        const options = {
            maintainAspectRatio: false,
            title: {
                display: true,
                text: this.props.title,
                fontColor: darkMode ? '#BBBBBB' : '#444444',
                fontSize: 16
            },
            legend: {
                labels: {
                    fontColor: darkMode ? '#BBBBBB' : '#444444'
                }
            },
            tooltips: {
                callbacks: {
                    // afterBody: (tooltipItems: any, data: any) => {
                    //     if (!this.props.onTooltip) {
                    //         return '';
                    //     }

                    //     return this.props.onTooltip(tooltipItems[0].datasetIndex);
                    // }
                    // label: (tooltipItem: any, data: any) => {
                    //     var label = data.datasets[tooltipItem.datasetIndex].label || '';

                    //     if (label) {
                    //         label += ': ';
                    //     }
                    //     label += Math.round(tooltipItem.yLabel * 100) / 100;
                    //     return label;
                    // }
                }
            },
            plugins: {
                labels: {
                    // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
                    render: 'percentage',

                    // precision for percentage, default is 0
                    precision: 0,

                    // identifies whether or not labels of value 0 are displayed, default is false
                    showZero: true,

                    // font size, default is defaultFontSize
                    fontSize: 12,

                    // font color, can be color array for each data or function
                    // for dynamic color, default is defaultFontColor
                    fontColor: '#fff',

                    // font style, default is defaultFontStyle
                    fontStyle: 'normal',

                    // font family, default is defaultFontFamily
                    fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

                    // draw text shadows under labels, default is false
                    textShadow: false,

                    // text shadow intensity, default is 6
                    shadowBlur: 10,

                    // text shadow X offset, default is 3
                    shadowOffsetX: 3,

                    // text shadow Y offset, default is 3
                    shadowOffsetY: 3,

                    // text shadow color, default is 'rgba(0,0,0,0.3)'
                    shadowColor: 'rgba(255,0,0,0.75)',

                    // draw label in arc, default is false
                    // bar chart ignores this
                    arc: false,

                    // position to draw label, available value is 'default', 'border' and 'outside'
                    // bar chart ignores this
                    // default is 'default'
                    position: 'default',

                    // draw label even it's overlap, default is true
                    // bar chart ignores this
                    overlap: true,

                    // show the real calculated percentages from the values and don't apply the additional
                    // logic to fit the percentages to 100 in total, default is false
                    showActualPercentages: true,

                    // add padding when position is `outside`
                    // default is 2
                    outsidePadding: 4,

                    // add margin of text when position is `outside` or `border`
                    // default is 2
                    textMargin: 4
                }
            }
        };

        return (
            <div className={this.props.className}>
                <div className="pie-chart">
                    <Pie data={data} options={options} />
                </div>
            </div>
        );
    }
}
